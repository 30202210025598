import ApplicationController from './application_controller';

export default class extends ApplicationController {

  static targets = ['sortSelectType', 'sortSelect'];

  initialize() { }
  setSort(event) {
    event.preventDefault();
    var ordering = event.currentTarget.dataset.sortBy.split('#');

    if (this.hasSortSelectTarget) {
      this.sortSelectTarget.value = ordering[0];
    } else {
      document.querySelector('#order_object').value = ordering[0];
    }

    if (this.hasSortSelectTypeTarget) {
      this.sortSelectTypeTarget.value = ordering[1];
    } else {
      document.querySelector('#order_type').value = ordering[1];
    }
    document.querySelector('#state_filter').dispatchEvent(new Event('change'));
  }
}
