import ApplicationController from './application_controller';

export default class extends ApplicationController {

  showButton(event) {
    event.preventDefault();
    var checkboxs = document.querySelectorAll('input.checkbox');
    var upgradeSelectedButtons = Array.from(document.querySelectorAll('div.footer div.left form input.button-blue'));
    var checkedInputs = Array.from(checkboxs).filter((checkbox) => {
      return checkbox.checked;
    }).length;
    var cupId = event.target.id.replace('cup_', '').replace('_upgrade', '');

    if (event.target.checked) {
      var inputForSelectedCupEl = document.createElement('input');
      inputForSelectedCupEl.type = 'hidden';
      inputForSelectedCupEl.name = "central_unit_ids[]";
      inputForSelectedCupEl.id = "central_unit_ids[]";
      inputForSelectedCupEl.value = cupId;
      upgradeSelectedButtons.forEach((upgradeSelectedButton) => {
        upgradeSelectedButton.parentElement.appendChild(inputForSelectedCupEl.cloneNode(true))
      });
    } else {
      upgradeSelectedButtons.forEach((upgradeSelectedButton) => {
        var inputForSelectedCupEl = upgradeSelectedButton.parentElement.querySelector('input[value^="' + cupId + '"');
        upgradeSelectedButton.parentElement.removeChild(inputForSelectedCupEl)
      })
    }

    if (checkedInputs > 0) {
      upgradeSelectedButtons.forEach((upgradeSelectedButton) => {
        upgradeSelectedButton.classList.remove('hidden');
      });
    } else {
      upgradeSelectedButtons.forEach((upgradeSelectedButton) => {
        upgradeSelectedButton.classList.add('hidden');
      });
    }
  }
}
