  import ModalController from '../modal_controller';

  export default class extends ModalController {

    redirectToPrint(event) {
      let input = event.currentTarget.closest('form').querySelector('#serial_number_series_list');
      var myArray = input.value.split(',');
      var myArrayQry = myArray.map(function(el, idx) {
        return 'serial_numbers[]=' + el;
      }).join('&');
      let newTab = window.open();
      newTab.location.href = event.currentTarget.dataset.url + "?" + myArrayQry;
      //window.open(event.currentTarget.dataset.url + "?" + params.toString(), '_blank')
      this.close(event);
    }

    show(event) {
      super.show(event);

      this.stimulate(
        'Manufacturing::SerialNumberSerieReflex#print_modal',
        event.currentTarget,
      );
    }
  }
