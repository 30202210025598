import ApplicationController from './application_controller';
import { DirectUpload } from '@rails/activestorage';

export default class extends ApplicationController {

  static targets = ['fileInput', 'dropzone', 'previews', 'previewTemplate'];

  initialize() {
    // Prevent default drag behaviours
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.dropzoneTarget.addEventListener(eventName, this.preventDefaults, false);
      document.body.addEventListener(eventName, this.preventDefaults, false);
    });
  }

  preventDefaults(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  dropHandler(event) {
    event.preventDefault();
    this.uploadFiles(event.dataTransfer.files);
    this.dropzoneTarget.classList.remove('highlight');
  }

  changeHandler(event) {
    this.uploadFiles(event.target.files);
  }

  dragEnterHandler() {
    console.log("detecting drag enter");
    if (!this.dropzoneTarget.classList.contains('highlight')) {
      this.dropzoneTarget.classList.add('highlight');
    }
  }

  dragOverHandler() {
    console.log("detecting drag over");
    if (!this.dropzoneTarget.classList.contains('highlight')) {
      this.dropzoneTarget.classList.add('highlight');
    }
  }

  dragLeaveHandler() {
    console.log("detecting drag leave");
    this.dropzoneTarget.classList.remove('highlight');
  }


  uploadFiles(files) {
    files = [...files];
    var url = this.fileInputTarget.dataset.directUploadUrl;
    var accepted_files = this.fileInputTarget.getAttribute('accept').split(',');

    files.forEach(file => {
      if (accepted_files.includes(file.type)) {
        var uploader = new Uploader(file, url, this.previewsTarget, this.previewTemplateTarget);
      } else {
        var unknown = this.fileInputTarget.dataset.unknownTypeMsg;
        var msg = this.fileInputTarget.dataset.invalidTypeMsg
          .replace('%NAME%', file.name)
          .replace('%TYPE%', file.type || unknown);
        alert(msg);
      }
    });

    // The uploaded files get their own input once DirectUpload is done. Clear the original input, otherwise the last
    // selected files are uploaded twice.
    this.fileInputTarget.value = null;
  }

  openFileDialog() {
    this.fileInputTarget.click();
  }

  destroyFile(event) {
    event.preventDefault();
    event.currentTarget.closest('.preview').remove();
  }
}

class Uploader {

  constructor(file, url, previews, template) {
    this.container = template.content.firstElementChild.cloneNode(true);

    this.container.querySelector('.name').textContent = file.name;
    previews.appendChild(this.container);

    this.upload = new DirectUpload(file, url, this);
    this.upload.create((error, blob) => {
      if (error) {
        console.error('Failed to upload file.', file, error);
      } else {
        // Upload finished.
        this.container.querySelector('input').value = blob.signed_id;
      }
    });
  }

  // This method is called automatically by DirectUpload (see 3rd param of its constructor).
  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', event => {
      var progress = Math.round((event.loaded / event.total) * 100);
      var progressBar = this.container.querySelector('.progress-bar');
      progressBar.style.width = `${progress}%`;
      progressBar.innerHTML = `${progress} %`;
    });
  }
}
