import ModalController from "./modal_controller";
import Rails from "@rails/ujs";

export default class extends ModalController {
  static targets = ["remoteForm"];
  show(event) {
    const asyncAction = event.target.dataset.asyncAction;

    if(asyncAction) {
      super.show(event);
      this.stimulate(asyncAction, event.target);
      return;
    }
    const modalContent = document.getElementById(event.target.dataset.modalContentId);

    if (!modalContent)  return;

    super.show(event);
    const clonedContent = modalContent.cloneNode(true);
    clonedContent.classList.remove('hidden');
    this.modal.innerHTML = '';
    this.modal.appendChild(clonedContent);
  }

  submit(event) {
    event.preventDefault();
    const asyncAction = event.target.dataset.asyncAction;
    const form = event.srcElement.closest("form");
    const spinnerDiv = form.querySelector("#spinner");
    this.spinnerOn(spinnerDiv);

    this.stimulate(asyncAction, event.target).then(() => {
      this.spinnerOff(spinnerDiv);
      this.closeModal();
    });
  }

  remoteFormSubmit(event) {
    event.preventDefault();
    console.log("remoteFormSubmit", this.remoteFormTarget);
    Rails.fire(this.remoteFormTarget, "submit");
  }

  remoteResult(event) {
    this.closeModal();
    const notification = event.detail[0].body.querySelector('.notification');
    const notificationContainer = document.querySelector('#show_event');
    notificationContainer.innerHTML = '';
    notificationContainer.appendChild(notification);
  }
}
