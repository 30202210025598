import ApplicationController from './application_controller';
import CableReady from 'cable_ready';
import consumer from '../channels/consumer';

export default class extends ApplicationController {

  static targets = ['logdst', 'pagination', 'loadMore', 'list'];

  initialize() {
    // Listen to updates of list of open sessions.
    consumer.subscriptions.create({
      channel: 'ManufacturingSessionsChannel'
    },
      {
        received(data) {
          if (data.cableReady) CableReady.perform(data.operations);
        }
      });

    // Listen to logging websocket.
    if (this.hasLogdstTarget && this.logdstTarget.dataset.logWs) {
      var ws = new WebSocket(this.logdstTarget.dataset.logWs);
      this.appendLog('Connecting to the logging websocket');

      ws.onopen = () => {
        this.appendLog('Connected to the logging websocket');
      }

      ws.onerror = () => {
        this.appendLog('Failed to connect to websocket')
      }

      ws.onmessage = (event) => {
        this.appendLog(event.data);
        this.analyze(event.data);
      }
    } else if (this.hasPaginationTarget) {
      console.log('List not done ManufacturingSesssion');
      document.addEventListener('stimulus-reflex:connected', () => {
        this.loadMore(new Event('init'));
      })
    }
  }

  analyze(message) {
    let matches = message.match(/Successfully read {(?<address>Address:\d)[^\[\]]*\[(?<values>[^\[\]]*)\]/)
    if (matches && matches.groups && matches.groups.address != null) {
      let address = matches.groups.address.split(':');
      let values = matches.groups.values.split(' ');
      let temperature = parseInt(values[0]) / 100.0;
      let voltage = parseInt(values[8]) / 100.0;
      let newSensorDiv = document.createElement('div');
      newSensorDiv.classList.add('sensor-' + address[1]);
      let span = document.createElement('span');
      span.textContent = 'Sensor with address: ' + address[1];
      newSensorDiv.appendChild(span);
      span = document.createElement('span');
      span.textContent = 'Temperature ' + temperature + '°C';
      newSensorDiv.appendChild(span);
      span = document.createElement('span');
      span.textContent = 'Voltage ' + voltage + 'V';
      newSensorDiv.appendChild(span);
      if (voltage < 11.00 || voltage > 13.00) {
        newSensorDiv.classList.add('error')
      } else {
        newSensorDiv.classList.add('success')
      }
      let sensorDiv = document.querySelector('.sensor-values .sensor-' + address[1]);

      if (sensorDiv == undefined) {
        let sensorsDiv = document.querySelector('.sensor-values');
        sensorsDiv.appendChild(newSensorDiv);
      } else {
        sensorDiv.parentElement.replaceChild(newSensorDiv, sensorDiv);
      }
      if (document.querySelector('.wrap input[type="submit"].ready')) {
        if (document.querySelectorAll('.sensor-values div.success').length == 1) {
          document.querySelector('.wrap input[type="submit"].ready').classList.remove('hidden');
          document.querySelector('.wrap input[type="submit"].confirm').classList.add('hidden');
        } else {
          document.querySelector('.wrap input[type="submit"].confirm').classList.remove('hidden');
          document.querySelector('.wrap input[type="submit"].ready').classList.add('hidden');
        }
      }

    }
  }

  // todo register after reflex to move target element
  finalizeReflex(element, reflex, noop, reflexId) {
    var loaded = document.querySelector('.body #loaded');
    var deliveries = list.querySelectorAll('div.manufacturing-session');
    deliveries.forEach(function (delivery) {
      loaded.appendChild(delivery);
    });
    this.spinnerOff();
  }

  loadMore(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.spinnerOn();

    if (this.hasLoadMoreTarget) {
      var outset = parseInt(this.loadMoreTarget.dataset.outset);
      var step = parseInt(this.loadMoreTarget.dataset.step);
      var target = this.loadMoreTarget;
    } else {
      var outset = parseInt(this.paginationTarget.dataset.outset);
      var step = parseInt(this.paginationTarget.dataset.step);
      var target = this.paginationTarget;
    }

    if (event.type != 'click') {
      outset = 0; // reset outset cause edit search field, filter or date
    }

    this.stimulate(
      'ManufacturingSessionsReflex#search',
      target,
      null,
      null,
      null,
      null,
      null,
      outset,
      step
    )
  }
}
