import ModalController from '../modal_controller';
import flatpickr from 'flatpickr';

export default class extends ModalController {
  static targets = ["discount"];

  applyDiscount(event) {
    let form = event.srcElement.closest('form');
    let discount = form.querySelector("#billing_subscription_discount").value;
    let fullPrice = form.querySelector("#billing_subscription_full_total_price").value;
    let vat_rate = form.querySelector("#billing_subscription_vat_rate").value;

    let totalPriceInput = form.querySelector("#billing_subscription_total_price");
    let totalPriceWithVatInput = form.querySelector("#billing_subscription_total_price_with_vat");

    totalPriceInput.value = ((1 - parseFloat(discount) / 100) * parseFloat(fullPrice)).toFixed(2);
    totalPriceWithVatInput.value = (parseFloat(totalPriceInput.value) + parseFloat(totalPriceInput.value) * vat_rate).toFixed(2);
  }

  changeService(event) {
    let form = event.srcElement.closest('form');
    let selectedPriceId = form.querySelector('#price_id')

    this.stimulate(
      'Billing::SubscriptionReflex#change_create_subscription',
      event.target,
      selectedPriceId.value
    ).then(payload => {
      Array.from(document.querySelectorAll('input[name^="due_date"]')).forEach(input => this.assingFlatpickr(input, 'setDueAt'));
    });
  }

  showCreateSubscription(event) {
    super.show(event);

    this.stimulate(
      'Billing::SubscriptionReflex#show_create_subscription',
      event.target
    ).then(payload => {
      Array.from(document.querySelectorAll('input[name^="due_date"]')).forEach(input => this.assingFlatpickr(input, 'setDueAt'));
    });
  }

  showSubscriptionSetting(event) {
    super.show(event);

    this.stimulate(
      'Billing::SubscriptionReflex#show_subscription_setting',
      event.target
    )
  }

  updateSubscriptionSetting(event) {
    let form = event.srcElement.closest('form');
    let checkBoxes = Array.from(form.querySelectorAll('input[type=checkbox]'));
    let serviceAcceptMap = {};
    checkBoxes.forEach(checkBox => {
      serviceAcceptMap[checkBox.id] = checkBox.checked
    });
    this.stimulate(
      'Billing::SubscriptionReflex#update_subscription_setting',
      event.target,
      serviceAcceptMap
    )
  }

  showEditSubscriptionModal(event) {
    super.show(event);

    this.stimulate(
      'Billing::SubscriptionReflex#show_edit_subscription',
      event.target
    ).then(payload => {
      //Array.from(document.querySelectorAll('input[name^="due_date"]')).forEach(input => this.assingFlatpickr(input, 'setDueAt'));
    });
  }

  destroySubscription(event) {
    let form = event.srcElement.closest('form');
    let spinnerDiv = form.querySelector('#spinner');
    this.spinnerOn(spinnerDiv);
    this.stimulate(
      'Billing::SubscriptionReflex#destroy_subscription',
      event.target
    ).then(_payload => {
      document.querySelector('#subscriptions').scrollIntoView();
    });
  }

  showDestroySubscriptionModal(event) {
    event.preventDefault();
    super.show(event);

    this.stimulate(
      'Billing::SubscriptionReflex#show_destroy_subscription',
      event.target
    );
  }

  assingFlatpickr(input) {
    var controller = this;
    flatpickr(input, {
      locale: this.getLocale(),
      dateFormat: 'd.m.Y',
    });
  }

  createSubscription(event) {
    event.preventDefault();
    let form = event.srcElement.closest('form');
    let spinnerDiv = form.querySelector('#spinner');
    this.spinnerOn(spinnerDiv);
    this.stimulate(
      'Billing::SubscriptionReflex#create_subscription',
      event.target,
    ).then(_payload => {
      document.querySelector('#subscriptions').scrollIntoView();
    });
  }

  updateSubscription(event) {
    event.preventDefault();
    let form = event.srcElement.closest('form');
    let spinnerDiv = form.querySelector('#spinner');
    this.spinnerOn(spinnerDiv);
    this.stimulate(
      'Billing::SubscriptionReflex#update_subscription',
      event.target,
    ).then(_payload => {
      document.querySelector('#subscriptions').scrollIntoView();
    });
  }
}
