import ApplicationController from './application_controller';
import CableReady from 'cable_ready';
import consumer from '../channels/consumer';

export default class extends ApplicationController {

  initialize() {
    // Listen to updates of list of open sessions.
    consumer.subscriptions.create({
      channel: 'ManufacturingDeliveriesChannel'
    },
    {
      received(data) {
        if (data.cableReady) CableReady.perform(data.operations);
      }
    });
  }
}
