import ModalController from './modal_controller';

export default class extends ModalController {

  initialize() {
    let last_known_scroll_position = 0;
    let ticking = false;

    window.onscroll = () => {
      last_known_scroll_position = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          this.checkScroll(last_known_scroll_position);
          ticking = false;
        });
      }

      ticking = true;
    };
  }

  checkScroll(position) {
    if (position > 0) {
      this.element.classList.add('scrolled');
    } else {
      this.element.classList.remove('scrolled');
    }
  }

}
