import ModalController from './modal_controller';

export default class extends ModalController {

  changeTemplate(event) {
    let documentsBlock = event.srcElement.closest('.documents');
    let deliveryInputs = documentsBlock.querySelectorAll('#template')

    deliveryInputs.forEach(function (input) {
      input.value = event.srcElement.dataset.template;
    });
  }

  changeCompany(event) {
    let documentsBlock = event.srcElement.closest('.documents');
    let deliveryInputs = documentsBlock.querySelectorAll('input[type="hidden"]#contact_by_company_id')
    deliveryInputs.forEach(function (input) {
      input.value = event.srcElement.value;
    });
  }

  changePrecision(event){
    let documentsBlock = event.srcElement.closest('.documents');
    let shapeRationInputs = documentsBlock.querySelectorAll('input#precision')

    shapeRationInputs.forEach(function (input) {
      input.value = event.srcElement.value;
    });
  }

  changeShapeRation(event) {
    let documentsBlock = event.srcElement.closest('.documents');
    let shapeRationInputs = documentsBlock.querySelectorAll('input#shape_ratio')

    shapeRationInputs.forEach(function (input) {
      input.value = event.srcElement.value;
    });
  }

  withoutFloorplan(event) {
    let documentsBlock = event.srcElement.closest('.documents');
    let deliveryInputs = documentsBlock.querySelectorAll('#without_floorplan')
    let checkBox = documentsBlock.querySelector('input[type="checkbox"]#without_floorplan');
    if (checkBox.checked == true) {
      deliveryInputs.forEach(function (input) {
        input.value = true;
      });
    } else {
      deliveryInputs.forEach(function (input) {
        input.value = null;
      });
    }
  }

  withExternalPdfs(event) {
    let documentsBlock = event.srcElement.closest('.documents');
    let deliveryInputs = documentsBlock.querySelectorAll('#with_external_pdf')
    let checkBox = documentsBlock.querySelector('input[type="checkbox"]#with_external_pdf');
    if (checkBox.checked == true) {
      deliveryInputs.forEach(function (input) {
        input.value = true;
      });
    } else {
      deliveryInputs.forEach(function (input) {
        input.value = null;
      });
    }
  }

  withGrayed(event){
    let documentsBlock = event.srcElement.closest('.documents');
    let deliveryInputs = documentsBlock.querySelectorAll('#with_grayed')
    let checkBox = documentsBlock.querySelector('input[type="checkbox"]#with_grayed');
    if (checkBox.checked == true) {
      deliveryInputs.forEach(function (input) {
        input.value = true;
      });
    } else {
      deliveryInputs.forEach(function (input) {
        input.value = null;
      });
    }
  }

  withDisabled(event){
    let documentsBlock = event.srcElement.closest('.documents');
    let deliveryInputs = documentsBlock.querySelectorAll('#with_disabled')
    let checkBox = documentsBlock.querySelector('input[type="checkbox"]#with_disabled');
    if (checkBox.checked == true) {
      deliveryInputs.forEach(function (input) {
        input.value = true;
      });
    } else {
      deliveryInputs.forEach(function (input) {
        input.value = null;
      });
    }
  }

  withDeliveryNote(event) {
    let documentsBlock = event.srcElement.closest('.documents');
    let deliveryInputs = documentsBlock.querySelectorAll('#delivery_id')
    let checkBox = documentsBlock.querySelector('input[type="checkbox"]#with_delivery_notes');
    if (checkBox.checked == true) {
      deliveryInputs.forEach(function (input) {
        input.value = checkBox.value;
      });
    } else {
      deliveryInputs.forEach(function (input) {
        input.value = null;
      });
    }
  }

  withDiscountAmount(event) {
    const documentBlock = event.srcElement.closest('.documents');
    const discountAmountInputs = documentBlock.querySelectorAll('input[type="hidden"]#with_discount_amount');

    discountAmountInputs.forEach(input => {
      input.value = event.currentTarget.checked ? true : null;
    })
  }

  showProposal(event) {
    super.show(event);

    this.stimulate(
      'DeliveriesReflex#proposal_pdf_language',
      event.currentTarget,
      event.currentTarget.dataset.installationId,
      event.currentTarget.dataset.proposalId,
      event.currentTarget.dataset.deliveryId
    )
  }

  showInstallation(event) {
    super.show(event);

    this.stimulate(
      'DeliveriesReflex#installation_pdf_language',
      event.currentTarget,
      event.currentTarget.dataset.installationId,
    )
  }

  submitForm(event) {
    event.preventDefault();
    event.srcElement.closest('form').submit();
  }
}
