import ApplicationController from './application_controller';
import moment from 'moment';

export default class extends ApplicationController {

  static targets = ['success', 'failed', 'time']

  onSuccess() {
    this.element.classList.add('active');
    this.successTarget.classList.add('active');
    this.failedTarget.classList.remove('active');

    moment.locale(document.body.dataset.locale);
    this.timeTarget.innerHTML = moment().format('LT');
  }

  onFail() {
    this.element.classList.add('active');
    this.successTarget.classList.remove('active');
    this.failedTarget.classList.add('active');
  }

}
