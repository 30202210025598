import ModalController from './modal_controller';
import CableReady from 'cable_ready';
import consumer from '../channels/consumer';
import Rails from "@rails/ujs"
import flatpickr from 'flatpickr';

export default class extends ModalController {

  static targets = ['pagination', 'form', 'list', 'active_sessions', 'parcelFirm', 'parcelTracking', 'shippedAt', 'deliveryType'];

  assingFlatpickr(input, method) {
    var controller = this;
    flatpickr(input, {
      locale: this.getLocale(),
      dateFormat: 'd.m.Y 23:59:59',
      onChange: function (selectedDates, dateStr, instance) {
        controller[method](selectedDates, dateStr, instance);
      }
    });
  }

  buildPPLCSV(){
    let checked = document.querySelectorAll('.checkbox-ppl input[type]:checked');
    let text = "CompanyName;Street;HouseNumber;City;ZIP;Country;DeliveryType;BackDelivery;PCS;COD;CODCurrency;VarSymbol;ConstSymbol;Contact;Phone;Email;Content;Price;PrintNote;LabelNote;CustReference;GuarantedEvenungDelivery;CheckAge;NextTry;ParcelReturn;PrepaidPickup;\n"
    checked.forEach(function(checkboxDiv){
      let pplValues = document.querySelectorAll('.delivery#' + checkboxDiv.id + ' .hidden .ppl-value');
      let row = Array.from({ length: 28 }, () => '');
      pplValues.forEach(function(pplValue){
        row[parseInt(pplValue.getAttribute('ppl_column'))- 1] = pplValue.getAttribute('ppl_value')
      });
      text += row.join(";") + ";\n"

    });
    return text;
  }

  countHwParts(event) {
    let hwPartCounts = [];
    event.srcElement.dataset.list.split(";").forEach(hwPartName => {
      let sumHwPart = 0
      document.querySelectorAll("span[data-hw-part='" + hwPartName + "']").forEach(element => {
        sumHwPart += parseInt(element.dataset.count);
      })
      hwPartCounts.push(hwPartName + ": " + sumHwPart);
    });
    console.log(hwPartCounts.join('\n'))
    navigator.clipboard.writeText(hwPartCounts.join('\n'))
      .then(() => {
        console.log("Summary copied to clipboard.");
        alert("Summary copied to clipboard.");
      }).catch(() => {
        console.error("Failed to copy.");
        alert(hwPartCounts.join('\n'));
      });
  }

  downloadPplCSV(event) {
    event.preventDefault();
    var a = document.createElement("a");
    let text = this.buildPPLCSV();
    let file = new Blob([text], {type: 'text/plain'});
    a.href = URL.createObjectURL(file);
    a.download = event.srcElement.getAttribute('download');
    a.click();
  }

  initialize() {
    document.addEventListener('stimulus-reflex:connected', () => {
      this.loadManufacturingSessions(new Event('init'));
    })

    this.sortSelectTarget = 'send_before';
    this.sortSelectType = 'asc';

    consumer.subscriptions.create({ channel: 'ManufacturingDeliveriesChannel' }, {
      received(data) {
        if (data.cableReady) CableReady.perform(data.operations);
      }
    });

    consumer.subscriptions.create({ channel: 'ActiveManufacturingSessionsChannel' }, {
      received(data) {
        if (data.cableReady) CableReady.perform(data.operations);
      }
    });

    document.querySelector('#search').focus();
    // TODO set all filters by query parameters
  }

  // todo register after reflex to move target element
  finalizeReflex(element, reflex, noop, reflexId) {
    if (reflex == 'DeliveriesReflex#search') {
      var loaded = document.querySelector('.body #loaded');
      var deliveries = list.querySelectorAll('div.delivery');
      deliveries.forEach(function (delivery) {
        loaded.appendChild(delivery);
      });
      this.spinnerOff();
    }
  }

  loadMore(event) {
    this.spinnerOn();
    event.preventDefault();
    this.setRequestId();
    this.setActionSource(event.srcElement.id);
    let from = document.querySelector('input#from');
    let to = document.querySelector('input#to');
    let queryFrom = document.querySelector('#time_from');
    let queryTo = document.querySelector('#time_to');
    if (from._flatpickr.selectedDates[0]) {
      queryFrom.value = from._flatpickr.selectedDates[0].toISOString();
    }
    if (to._flatpickr.selectedDates[0]) {
      to._flatpickr.selectedDates[0].setHours(23);
      to._flatpickr.selectedDates[0].setMinutes(59);
      queryTo.value = to._flatpickr.selectedDates[0].toISOString();
    }

    Rails.fire(this.formTarget, 'submit');
  }

  markToPpl(event) {
    if (event.srcElement.checked) {
      let deliveries = document.querySelectorAll('div.delivery')
      deliveries.forEach(function (delivery) {
        let deliveryId = delivery.id
        let divWithChecbox = document.createElement('div');
        let checkBox = document.createElement('input');
        divWithChecbox.classList.add('checkbox-ppl')
        checkBox.id = deliveryId;
        checkBox.setAttribute('type', 'checkbox');
        divWithChecbox.appendChild(checkBox);
        delivery.after(divWithChecbox);
        divWithChecbox.setAttribute('style', 'top: -' + (delivery.offsetHeight + checkBox.offsetHeight )+ 'px; margin-bottom: -' + divWithChecbox.offsetHeight + 'px');
      });
    } else {
      let deliveries = document.querySelectorAll('div.checkbox-ppl');
      deliveries.forEach(function(checkboxDiv){
        checkboxDiv.remove();
      });
    }
    document.querySelector('div#download-ppl-CSV').classList.toggle('hidden');
  }

  result(event) {
    let requestId = event.detail[0].body.querySelector('#request_id');
    if (requestId != undefined && this.requestId == requestId.value) {
      let actionSource = event.detail[0].body.querySelector('#action_source').value;
      var list = document.querySelector('.body #list');
      if (actionSource == 'pagination-link') {
        var deliveries = event.detail[0].body.querySelectorAll('div.delivery');
        deliveries.forEach(function (delivery) {
          list.appendChild(delivery);
        });
      } else {
        var deliveriesList = event.detail[0].body.querySelector('body #list');
        list.parentElement.replaceChild(deliveriesList, list);
      }
      this.replaceElementWithNewBySingleQuery('div#pagination', event)
      this.replaceElementWithNewBySingleQuery('#outset', event)
      this.replaceElementWithNewBySingleQuery('#step', event)
      this.spinnerOff();
    }
  }

  resultPagination(event) {
    const data = event.detail[0].body.innerHTML
    if (data.length > 0) {
      var loaded = document.querySelector('.body #loaded');
      var deliveries = event.detail[0].body.querySelectorAll('div.delivery');
      deliveries.forEach(function (delivery) {
        loaded.appendChild(delivery);
      });
      this.spinnerOff();
      return
    }

    // You could also show a div with something else?
    this.listTarget.innerHTML = '<p>No matching results found</p>'
  }

  error(event) {
    console.log(event)
  }

  loadManufacturingSessions(event) {
    this.stimulate(
      'ManufacturingSessionsReflex#check_active',
    );
  }

  changeDeliveryType(event) {
    event.preventDefault();
    let spinnerDiv = event.srcElement.closest('form').querySelector('#spinner');
    let controller = this;
    this.spinnerOn(spinnerDiv);
    this.stimulate('DeliveriesReflex#change_delivery_type', event.target, document.querySelector('#delivery_delivery_type').value).then(_payload => {
      Array.from(document.querySelectorAll('input[id^="delivery_shipped_at"]')).forEach(input => this.assingFlatpickr(input, 'setShippedAt'));
    }).finally(() => { controller.spinnerOff(spinnerDiv); });
  }

  changeParcelTracking(event) {
    event.preventDefault();
    let spinnerDiv = event.srcElement.closest('form').querySelector('#spinner');
    let controller = this;
    this.spinnerOn(spinnerDiv);
    this.stimulate('DeliveriesReflex#change_parcel_tracking', event.target,
      this.deliveryTypeTarget.value,
      this.parcelTrackingTarget.value).then(_payload => {
        Array.from(document.querySelectorAll('input[id^="delivery_shipped_at"]')).forEach(input => this.assingFlatpickr(input, 'setShippedAt'));
      }).finally(() => { controller.spinnerOff(spinnerDiv); });
  }

  showShipDeliveryModal(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    super.show(event);
    this.stimulate('DeliveriesReflex#show_ship_delivery_modal', event.target).then(_payload => {
      Array.from(document.querySelectorAll('input[id^="delivery_shipped_at"]')).forEach(input => this.assingFlatpickr(input, 'setShippedAt'));
    });
  }

  shipDelivery(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    let spinnerDiv = event.srcElement.closest('form').querySelector('#spinner');
    let controller = this;
    this.spinnerOn(spinnerDiv);

    this.stimulate('DeliveriesReflex#ship_delivery', event.target,
      this.deliveryTypeTarget.value,
      this.shippedAtTarget.value,
      this.parcelFirmTarget.value, this.parcelTrackingTarget.value,
    ).then(payload => {
      Array.from(document.querySelectorAll('input[id^="delivery_shipped_at"]')).forEach(input => this.assingFlatpickr(input, 'setShippedAt'));
      if (payload.event.detail.html) {
        let parser = new DOMParser();
        let element = parser.parseFromString(payload.event.detail.html, 'text/html');
        let redirectLink = element.querySelector('a#redirect_to');
        if (redirectLink != undefined) {
          this.closeModal();
          const success_event = new CustomEvent('save-indicator:success', { detail: payload });
          window.dispatchEvent(success_event);
          window.location.replace(redirectLink.getAttribute('href'))
        }
      }
    }).finally(() => { controller.spinnerOff(spinnerDiv); });
  }
}
