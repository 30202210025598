import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['header', 'content'];

    toggleContent(event) {
        if (event.target.tagName.toLowerCase() === 'a') {
            return;
        }

        event.preventDefault();
        const { expandIcon, closeIcon } = this.headerTarget.dataset;
        const icon = this.headerTarget.querySelector('.icon');
        const content = this.contentTarget;
        const isClosed = content.classList.contains('closed');

        this.headerTarget.classList.toggle('open');
        icon.classList.toggle(expandIcon);
        icon.classList.toggle(closeIcon);

        content.style.height = isClosed ? `${content.scrollHeight}px` : '0';
        content.classList.toggle('closed');
        content.classList.toggle('open');
    }
}