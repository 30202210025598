import ModalController from '../modal_controller';
import Rails from "@rails/ujs"

export default class extends ModalController {
  static targets = ['form', 'results', 'update'];
  connect() {
    document.addEventListener("autocomplete.change", this.select.bind(this))
    super.connect();
  }

  show(event) {
    super.show(event);

    Rails.fire(this.formTarget, 'submit');
  }

  editResult(event) {
    this.spinnerOff();
    let modalContent = event.detail[0].body.querySelector('.modal-dialog');
    let waitingModal = document.querySelector('.modal-dialog');
    waitingModal.parentNode.replaceChild(modalContent, waitingModal);
  }

  select(event) {
    console.log(event.target.dataset)
    if (event.target.dataset.monitoringAlertRules == 'true') {

    } else if (event.target.dataset.installations == 'true') {
      let installationId = event.detail.value;
      let installationName = event.detail.textValue;

      let applyToList = document.querySelector('.apply-to');
      let selectedIds = document.querySelector('#selected_ids');
      if (selectedIds.value.search(installationId) < 0 ) {
        selectedIds.value = selectedIds.value + ',' + installationId;

        let div = document.createElement("div");
        let span = document.createElement("span");
        let spanRemove = document.createElement("span");
        span.textContent = installationName;
        spanRemove.classList.add('icon-close');
        div.classList.add('item');
        spanRemove.setAttribute('data-installation-id', installationId);
        spanRemove.setAttribute('data-action', 'click->monitoring--customization-modal#removeInstallation');
        div.appendChild(span);
        div.appendChild(spanRemove);
        applyToList.appendChild(div);
      }

      document.querySelector('#installation_name').value = '';
    }

  }

  removeInstallation(event) {
    console.log(event.target);
    let selectedIds = document.querySelector('#selected_ids');
    selectedIds.value = selectedIds.value.replace(event.target.dataset.installationId, '');
    selectedIds.value = selectedIds.value.replace(',,', ',');
    event.target.parentElement.remove();
  }

  update(_event) {
    Rails.fire(this.updateTarget, 'submit');
    this.spinnerOn();
  }

  updateResult(event) {
    console.log(event);
    this.closeModal();
    location.reload();
  }

}


