import ApplicationController from './application_controller'

export default class extends ApplicationController {

  initialize(){
    document.addEventListener('stimulus-reflex:connected', () => {
      this.setFormUrl();
    })
  }

  setFormUrl() {
    let button = document.querySelector('#fire-design');
    if (button != undefined) {
      let checkbox = document.querySelector('#acknowledgment_checkbox');
      let form = button.closest('form');
      if (button.disabled) {
        form.setAttribute('action', checkbox.dataset.formDisabled);
      } else {
        form.setAttribute('action', checkbox.dataset.formEnabled)
      }
    }
  }

  toggleButton(_event) {
    let button = document.querySelector('#fire-design');
    button.toggleAttribute('disabled');
    this.setFormUrl();
  }
}
