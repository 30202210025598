import ModalController from './modal_controller';

export default class extends ModalController {

  static targets = ['destroyFloorplan', 'changePlacementsFloorplan', 'delete', 'move'];

  changePlacementsFloorplanModal(event) {
    event.preventDefault();
    super.show(event);
    this.stimulate('FloorplanReflex#change_floorplan_modal', event.target)
  }

  copyFloorplanModal(event) {
    event.preventDefault();
    super.show(event);
    this.stimulate('FloorplanReflex#copy_floorplan_modal', event.target)
  }

  destroyFloorplanModal(event) {
    event.preventDefault();
    super.show(event);
    this.stimulate('FloorplanReflex#destroy_floorplan_modal', event.target)
  }

  destroyFloorplanOriginalModal(event) {
    event.preventDefault();
    super.show(event);
    this.stimulate('FloorplanReflex#destroy_floorplan_original_modal', event.target)
  }

  changePlacementsAction(event) {
    let formEl = event.currentTarget.closest('.modal-dialog').querySelector('form');
    let selectBoxes = formEl.querySelectorAll('select:not(#all)');
    let checkBoxAll = formEl.querySelector('#select_all');
    let selectAll = formEl.querySelector('#all')

    if (this.deleteTarget.id == event.currentTarget.id) {
      selectBoxes.forEach(function (selectBox) {
        selectBox.disabled = true;
      });
      checkBoxAll.disabled = true;
      checkBoxAll.checked = false;
      selectAll.classList.add('hidden');
      selectAll.disabled = true;
    } else {
      selectBoxes.forEach(function (selectBox) {
        selectBox.disabled = false;
      });
      checkBoxAll.disabled = false;
      checkBoxAll.checked = false;
      selectAll.disabled = false;
      selectAll.classList.add('hidden')
    }
  }

  copyFloorplan(event) {
    event.preventDefault();
    let actionsDiv = event.currentTarget.closest('.actions')
    actionsDiv.classList.toggle('hidden');
    let formEl = event.currentTarget.closest('.modal-dialog').querySelector('form');
    formEl.classList.toggle('hidden');
    const spinnerElement = event.currentTarget.closest('.modal-dialog').querySelector('#spinner')
    this.spinnerOn(spinnerElement);
    
    this.stimulate('FloorplanReflex#copy_floorplan', formEl).then(() => {
      this.spinnerOff(spinnerElement);
    })
  }

  toggleSelectAll(event) {
    let formEl = event.currentTarget.closest('.modal-dialog').querySelector('form');
    let checkBoxAll = formEl.querySelector('#select_all');
    if (checkBoxAll.disabled) {
      return;
    }
    let selectBoxes = formEl.querySelectorAll('select:not(#all)');
    formEl.querySelector('#all').classList.toggle('hidden');
    formEl.querySelectorAll('#all_empty').forEach(function (element) {
      element.classList.toggle('hidden');
    })
    if (checkBoxAll.checked) {
      selectBoxes.forEach(function (selectBox) {
        selectBox.disabled = true;
      });
    } else {
      selectBoxes.forEach(function (selectBox) {
        selectBox.disabled = false;
      });
    }
  }

  getMapOfMovements(formEl) {
    let map = {};
    if (formEl.querySelector('#select_all') && formEl.querySelector('#select_all').checked) {
      map['all'] = formEl.querySelector('#all').value;
    } else {
      formEl.querySelectorAll('select:not(#all)').forEach(function (selectBox) {
        map[selectBox.name] = selectBox.value;
      });
    }
    return map;
  }

  applyChangeFloorplans(event) {
    event.preventDefault();
    let actionsDiv = event.currentTarget.closest('.actions')
    actionsDiv.classList.toggle('hidden');
    let formEl = event.currentTarget.closest('.modal-dialog').querySelector('form');
    formEl.classList.toggle('hidden');
    const spinnerElement = event.currentTarget.closest('.modal-dialog').querySelector('#spinner')
    this.spinnerOn(spinnerElement);


    this.stimulate('FloorplanReflex#change_floorplans', event.currentTarget, this.getMapOfMovements(formEl))
    .then(() => {
      this.spinnerOff(spinnerElement);
    });
  }

  applyDestroyFloorplan(event) {

    let formEl = event.currentTarget.closest('.modal-dialog').querySelector('form');
    let actions = {}
    if (this.deleteTarget.checked) {
      actions['delete'] = 'all';
    } else {
      actions['move'] = this.getMapOfMovements(formEl);
    }
    this.stimulate('FloorplanReflex#destroy_floorplan', event.currentTarget, actions);
  }

  applyDestroyFloorplanOriginal(event) {
    event.preventDefault();
    this.stimulate('FloorplanReflex#destroy_floorplan_original', event.currentTarget);
  }
  temporalUnlockProposal(event) {
    event.preventDefault();
    console.log(event.currentTarget.dataset.proposalId);
    this.stimulate(
      'FloorplanReflex#temporal_unlock_proposal',
      event.currentTarget,
      event.currentTarget.dataset.proposalId
    );
  }
}
