import ApplicationController from "./application_controller";
import flatpickr from "flatpickr";
import { Czech } from "flatpickr/dist/l10n/cs.js";
import { Eng } from "flatpickr/dist/l10n/default.js";

export default class extends ApplicationController {
  static targets = ["inputDate"];

  initialize() {
    let locale = document.querySelector("#locale");
    if (locale == "en") {
      locale = Eng;
    } else {
      locale = Czech;
    }

    this.inputDateTargets.forEach((element) => {
      const dateFormat = element.dataset.dateFormat;
      const altFormat = element.dataset.altFormat;
      const altInput = element.dataset.altInput;
      flatpickr(element, {
        locale: locale,
        dateFormat: dateFormat || "d.m.Y", // date format should set be in some element on page
        altFormat: altFormat || "d.m.Y",
        altInput: altInput || false,
      });
    });

    // flatpickr(this.toTarget, {
    //   locale:  locale,
    //   dateFormat: 'd.m.Y',
    // });
  }
}
