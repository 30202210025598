  import ModalController from '../modal_controller';
  import Rails from "@rails/ujs"

  export default class extends ModalController {
    static targets = ['form', 'edit', 'update', 'add', 'destroy'];

    addItem(event) {
      let itemType = this.addTarget.dataset.itemType;
      let parentDiv = document.querySelector('#' + itemType + '-list');
      let inputs = parentDiv.querySelectorAll('[id^=' + itemType + '_key_]');
      let nextIndex = inputs.length;
      let modalDialog = document.querySelector('.modal-dialog')

      if (this.addTarget.dataset.inputValue == 'true'){
        let pairDiv = document.createElement('div')
        pairDiv.setAttribute('class', 'pair');
        pairDiv.appendChild(this.copyHiddenInput(modalDialog, itemType, 'key', nextIndex));
        pairDiv.appendChild(this.copyHiddenInput(modalDialog, itemType, 'value', nextIndex));
        parentDiv.appendChild(pairDiv)
      } else {
        parentDiv.appendChild(this.copyHiddenInput(modalDialog, itemType, 'key', nextIndex));
      }
    }

    copyHiddenInput(rootDiv, itemType, subtype, nextIndex){
      let template = rootDiv.querySelector('input#' + itemType + '_' + subtype + '[type=hidden]').cloneNode(true);
      template.removeAttribute('type');
      template.setAttribute('name', itemType + '_' + subtype + '[' + nextIndex + ']');
      template.setAttribute('id', itemType + '_' + subtype + '_' + nextIndex);
      return template;
    }

    show(event) {
      super.show(event);

      Rails.fire(this.formTarget, 'submit');
    }

    editResult(event) {
      this.spinnerOff();
      let modalContent = event.detail[0].body.querySelector('.modal-dialog');
      let waitingModal = document.querySelector('.modal-dialog');
      waitingModal.parentNode.replaceChild(modalContent, waitingModal);
    }

    update(event) {
      Rails.fire(this.updateTarget, 'submit');
      this.element.parentElement.append(this.createSpinnerElement());
      this.spinnerOn();
    }

    destroy(event) {
      Rails.fire(this.destroyTarget, 'submit');
      this.element.parentElement.append(this.createSpinnerElement());
      this.spinnerOn();
    }

    updateAndApply(event) {
      document.querySelector('#apply_to_all').value = 'true'

      this.update(event);
    }

    updateResult(event) {
      console.log(event);
      this.closeModal();
      location.reload();
    }
  }
