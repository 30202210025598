import ModalController from '../modal_controller';
import Rails from "@rails/ujs"

export default class extends ModalController {

  static targets = ['form', 'monitorable_type'];

  editContactableModal(event) {
    super.show(event);

    this.stimulate(
      'Monitoring::Settings#show_edit_contactable',
      event.currentTarget,
    )
  }

  editSendibleModal(event) {
    super.show(event);

    this.stimulate(
      'Monitoring::Settings#show_edit_sendible',
      event.currentTarget,
    )
  }

  setIcon(event) {
    document.querySelector('span#' + event.currentTarget.id).setAttribute('class', 'notify ' + event.currentTarget.options[event.currentTarget.selectedIndex].value)
  }
}
