import ApplicationController from './application_controller';
import pdfjsLib from 'pdfjs-dist/webpack';

export default class extends ApplicationController {

  static targets = ['progressBar'];

  initialize() {
    this.pdfs = {}
    this.label = this.progressBarTarget.innerHTML;
  }

  loadPDF(file_url, file_id) {
    if (this.pdfs[file_id] && this.pdfs[file_id].promise) {
      return this.pdfs[file_id].promise;
    } else {
      if (!this.pdfs[file_id]) {
        this.pdfs[file_id] = {};
      }

      var pdf = pdfjsLib.getDocument(file_url);

      pdf.onProgress = (data) => { this.updateProgressBar(file_id, data) }
      this.pdfs[file_id].promise = pdf.promise;
      return this.pdfs[file_id].promise;
    }
  }

  fileSelected() {
    this.element.submit();
  }

  updateProgressBar(file_id, data) {
    this.pdfs[file_id].loaded = data.loaded;
    this.pdfs[file_id].total = data.total;

    var sum_loaded = 0;
    var sum_total = 0;
    Object.entries(this.pdfs).forEach((pdf) => { sum_loaded += (pdf[1].loaded || 0); });
    Object.entries(this.pdfs).forEach((pdf) => { sum_total += (pdf[1].total || 0); });

    var progress = Math.round((sum_loaded / sum_total) * 100);
    this.progressBarTarget.parentNode.style.display = 'flex';

    this.progressBarTarget.style.width = `${progress}%`;
    this.progressBarTarget.innerHTML = `${this.label} ${progress} %`;
    if (progress >= 100) {
      this.progressBarTarget.parentNode.style.display = 'none';
    }
  }
}
