import ModalController from "./modal_controller";
import Rails from "@rails/ujs";

export default class extends ModalController {
  static targets = [
    "placements_list",
    "sensor_product",
    "floor",
    "placement_name",
    "condition_logic",
    "installation_id",
    "form",
    "bulk_add_modal",
    "selected_add_modal",
    "add_selected",
    "placement_checkbox",
    "placement_number",
    "placement_number_error",
  ];

  selectedPlacementIds = [];
  debounceFilterTimer = null;

  connect() {
    super.connect();
    document.addEventListener("form_submitted", (event) => {
      this.spinnerOff();
    });
  }

  filter(event) {
    event.preventDefault();

    this.spinnerOn();

    Rails.fire(this.formTarget, "submit");
  }

  debounceFilter(event) {
    clearTimeout(this.debounceFilterTimer);

    this.debounceFilterTimer = setTimeout(() => {
      this.filter(event);
    }, 1000);
  }

  intervalFilter(event) {
    const value = event.currentTarget.value;

    const intervalsValid = value
      .split(",")
      .filter((interval) => interval !== "")
      .every((interval) => {
        return interval.match(/^\d+(-\d+)?$/);
      });

    if (intervalsValid) {
      this.debounceFilter(event);
      this.placement_number_errorTarget.classList.add("hidden");
      return;
    }
    clearTimeout(this.debounceFilterTimer);
    this.placement_number_errorTarget.classList.remove("hidden");
  }

  loadMore(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.spinnerOn();

    const outset = this.placement_checkboxTargets.length;
    const product_id = this.sensor_productTarget.value;
    const floor = this.floorTarget.value;
    const placement_name = this.placement_nameTarget.value;
    const installation_id = this.installation_idTarget.value;
    const condition_logic = this.condition_logicTarget.value;
    const placement_number = this.placement_numberTarget.value;

    this.stimulate("TagReflex#load_more_placements", event.currentTarget, {
      outset: outset,
      product_id: product_id,
      floor: floor,
      placement_name: placement_name,
      installation_id: installation_id,
      condition_logic: condition_logic,
      placement_number: placement_number,
    }).then(() => {
      this.spinnerOff();
    });
  }

  findTagIds(event) {
    let allTagsHidden = event.srcElement
      .closest("form")
      .querySelectorAll('[name="tags[]"]');
    let tagIds = new Array();
    allTagsHidden.forEach((hiddenTagId) => {
      tagIds.push(hiddenTagId.value);
    });
    return tagIds;
  }

  showBulkAddModal(event) {
    const modal = this.bulk_add_modalTarget;

    modal.classList.toggle("hidden");

    this.stimulate("TagReflex#show_bulk_add_modal", event.target);
  }

  showSelectedAddModal(event) {
    const modal = this.selected_add_modalTarget;

    modal.classList.toggle("hidden");

    this.stimulate(
      "TagReflex#show_selected_add_modal",
      event.target,
      this.selectedPlacementIds
    );
  }

  close(event) {
    const modalToCLose = event.currentTarget.dataset.modal;

    let modal;

    switch (modalToCLose) {
      case "bulk":
        modal = this.bulk_add_modalTarget;
        break;
      case "selected":
        modal = this.selected_add_modalTarget;
        break;
    }

    modal.innerHTML = "";

    modal.classList.toggle("hidden");
  }

  bulkSavePlacementsTags(event) {
    event.preventDefault();

    this.spinnerOn();

    const product_id = this.sensor_productTarget.value;
    const floor = this.floorTarget.value;
    const placement_name = this.placement_nameTarget.value;
    const installation_id = this.installation_idTarget.value;
    const condition_logic = this.condition_logicTarget.value;
    const placement_number = this.placement_numberTarget.value;
    const action_type = event.currentTarget.dataset.actionType;

    const tag_ids = this.findTagIds(event);

    this.stimulate("TagReflex#save_bulk_placements_tags", event.currentTarget, {
      tag_ids: tag_ids,
      product_id: product_id,
      floor: floor,
      placement_name: placement_name,
      installation_id: installation_id,
      condition_logic: condition_logic,
      placement_number: placement_number,
      action_type: action_type,
    }).then(() => {
      this.spinnerOff();
      this.close({ currentTarget: { dataset: { modal: "bulk" } } });
    });
  }

  saveSelectedPlacementsTags(event) {
    event.preventDefault();

    this.spinnerOn();

    const tag_ids = this.findTagIds(event);

    const action_type = event.currentTarget.dataset.actionType;

    this.stimulate(
      "TagReflex#save_selected_placements_tags",
      event.currentTarget,
      tag_ids,
      this.selectedPlacementIds,
      action_type
    ).then(() => {
      this.spinnerOff();
      this.uncheckAllPlacements();
      this.toggleSelectedAddButton(event);
      this.close({ currentTarget: { dataset: { modal: "selected" } } });
    });
  }

  togglePlacementSelection(event) {
    event.preventDefault();

    const placement_id = event.currentTarget.dataset.placementId;

    if (this.selectedPlacementIds.includes(placement_id)) {
      this.selectedPlacementIds = this.selectedPlacementIds.filter(
        (id) => id !== placement_id
      );
    } else {
      this.selectedPlacementIds.push(placement_id);
    }

    this.toggleSelectedAddButton(event);
  }

  toggleSelectedAddButton(event) {
    event.preventDefault();

    const selected_add = this.add_selectedTarget;

    if (this.selectedPlacementIds.length > 0) {
      selected_add.classList.remove("hidden");
    } else {
      selected_add.classList.add("hidden");
    }
  }

  uncheckAllPlacements() {
    const placement_checkboxes = this.placement_checkboxTargets;

    placement_checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    this.selectedPlacementIds = [];
  }
}
