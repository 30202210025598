import ModalController from "./modal_controller";

export default class extends ModalController {
  static targets = ["addInstallationGroup", "groupName"];

  showAddInstallationGroup(event) {
    super.show(event);

    this.stimulate("InstallationGroupReflex#show_add_installation_group", event.target);
  }

  submitInstallationGroupForm(event) {
    event.preventDefault();

    let form = event.srcElement.closest("form");
    let spinnerDiv = form.querySelector("#spinner");
    this.spinnerOn(spinnerDiv);

    this.stimulate("InstallationGroupReflex#submit_installation_group_form", event.target).then((_payload) => {
      this.spinnerOff(spinnerDiv);
      this.closeModal();
    });
  }

  removeInstallationGroup(event) {
    this.stimulate("InstallationGroupReflex#remove_installation_group", event.target);
  }

  markAsPrimary(event) {
    this.stimulate("InstallationGroupReflex#mark_as_primary", event.target);
  }

  toggleGroupName(event) {
    this.groupNameTarget.classList.toggle("hidden");
  }
}
