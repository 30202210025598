import ApplicationController from './application_controller';
import CableReady from 'cable_ready';
import consumer from '../channels/consumer';

export default class extends ApplicationController {

  static targets = ['logdst'];

  initialize() {
    super.initialize();
    // Listen to updates of list of open sessions.
    let mainController = this;

    consumer.subscriptions.create({
      channel: 'CentralUnitChannel', central_unit_id: this.logdstTarget.dataset.centralUnitId,
      locale: document.querySelector('body').dataset.locale
    },
      {
        received(data) {
          if (data.cableReady) {
            CableReady.perform(data.operations);
            mainController.showTimesAndDatesInLocalZone();
          } else  {
            if (data.message) {
              mainController.appendLog(data.message);
            }
            if (data.data) {
              mainController.appendLog(data.data);
            }
            if (data.redirect_to) {
              document.location = data.redirect_to;
            }
          }
        }
      });
  }
}
