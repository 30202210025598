import ApplicationController from './application_controller';

export default class extends ApplicationController {

  static targets = ['button', 'page'];

  toggleOne(event) {
    if (event.target.type !== 'checkbox') {
      event.preventDefault();
    }
    let toggleElement = document.querySelector('#' + event.target.dataset.toggleId);
    let iconClassOn = event.target.dataset.iconClassOn || 'icon-down';
    let iconClassOff = event.target.dataset.iconClassOff || 'icon-right'

    toggleElement.classList.toggle('hidden')
    if (event.target.dataset.toggleClass) {
      toggleElement.classList.toggle(event.target.dataset.toggleClass)
    }

    let subIcon = event.target.querySelector(iconClassOff) || event.target.querySelector(iconClassOn);
    if (subIcon) {
      subIcon.classList.toggle(iconClassOn)
      subIcon.classList.toggle(iconClassOff)
    } else {
      event.target.classList.toggle(iconClassOn)
      event.target.classList.toggle(iconClassOff)
    }

  }

  toggleList(event) {
    var toggleElements = document.querySelectorAll('#' + event.target.dataset.toggleId);
    toggleElements.forEach((toggleElement) => {
      toggleElement.classList.toggle('hidden')
      if (event.target.dataset.toggleClass) {
        toggleElement.classList.toggle(event.target.dataset.toggleClass)
      }
    });

  }
}
