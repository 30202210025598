import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["webhookPartnerRoute", "placementProductId", "placementFloorPlanId", "placementIdInput", "nameInput",
    "notesInput", "tagsInput", "addrInput", "snInput", "hwVerInput", "fwVerInput", "hwVersion", "placementBusId"]

  connect() {
    super.connect();

    this.updatePromise = null;
    this.show();
  }

  show() {
    this.stimulate(
      'PlacementPopoverReflex#show',
      this.element,
      this.element.dataset.placementId,
    ).then(payload => {
      this.nameInputTarget.focus();
    });
  }

  hide(event) {
    var target = event.currentTarget;
    var originalLabel = target.innerHTML;
    if (this.updatePromise !== null) {
      target.innerHTML = target.dataset.stimulusDisableWith;
      this.updatePromise.then(() => {
        target.innerHTML = originalLabel;
        target.closest('[data-tippy-root]')._tippy.hide()
      });
    } else {
      target.closest('[data-tippy-root]')._tippy.hide()
    }
  }

  updateExternal(event) {
    this.updatePromise = this.stimulate(
      'PlacementPopoverReflex#update_external',
      event.target,
      this.placementFloorPlanIdTarget.value, // change also order in then part
      this.placementProductIdTarget.value,
      this.placementIdInputTarget.value,
      this.nameInputTarget.value,
      this.notesInputTarget.value,
      this.tagsInputTarget.value
    )
      .then(payload => {
        const success_event = new CustomEvent('save-indicator:success', { detail: payload });
        if (payload.data.dataset['data-target'] == 'placement-popover.placementFloorPlanId') {
          let newFloorplan = document.querySelector('#floorplan-' + payload.data.args[1] + ' .floorplan');
          // TODO
          // find way how to update tipy on template element
        }
        window.dispatchEvent(success_event);
      })
      .catch(payload => {
        const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
        window.dispatchEvent(failed_event);
      })
      .finally(() => {
        this.updatePromise = null;
      });
  }

  update(event) {
    this.updatePromise = this.stimulate(
      'PlacementPopoverReflex#update',
      event.target,
      this.placementFloorPlanIdTarget.value, // change also order in then part
      this.placementProductIdTarget.value,
      this.hasWebhookPartnerRouteTarget ? this.webhookPartnerRouteTarget.value : null,
      this.placementIdInputTarget.value,
      this.nameInputTarget.value,
      this.notesInputTarget.value,
      this.tagsInputTarget.value,
      this.addrInputTarget.value,
      this.snInputTarget.value,
      this.hwVerInputTarget.value,
      this.fwVerInputTarget.value,
      this.hwVersionTarget.value,
      this.hasPlacementBusIdTarget ? this.placementBusIdTarget.value : null,
    )
      .then(payload => {
        const success_event = new CustomEvent('save-indicator:success', { detail: payload });
        if (payload.data.dataset['data-target'] == 'placement-popover.placementFloorPlanId') {
          let newFloorplan = document.querySelector('#floorplan-' + payload.data.args[1] + ' .floorplan');
          // TODO
          // find way how to update tipy on template element
        }
        window.dispatchEvent(success_event);
      })
      .catch(payload => {
        const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
        window.dispatchEvent(failed_event);
      })
      .finally(() => {
        this.updatePromise = null;
      });
  }

  destroy(event) {
    var target = event.currentTarget;
    var originalLabel = target.innerHTML;

    target.innerHTML = target.dataset.stimulusDisableWith;
    var currentTab = document.querySelector('.controls ul li.active');
    this.stimulate(
      'PlacementPopoverReflex#destroy',
      event.target,
      this.placementIdInputTarget.value,
    )
      .then(payload => {
        target.innerHTML = originalLabel;

        const success_event = new CustomEvent('save-indicator:success', { detail: payload });
        window.dispatchEvent(success_event);

        // TODO ugly same code is in tabs_controller
        document.querySelectorAll('.controls ul li').forEach(
          function (tabSelector) {
            if (tabSelector.dataset.tabTarget === currentTab.dataset.tabTarget) {
              tabSelector.classList.add('active');
            } else {
              tabSelector.classList.remove('active');
            }
          }
        );

        document.querySelectorAll('.placements').forEach(
          function (floorplan) {
            if (floorplan.id === currentTab.dataset.tabTarget) {
              floorplan.style.display = currentTab.dataset.display || 'block';
            } else {
              floorplan.style.display = 'none';
            }
          }
        );
      })
      .catch(payload => {
        const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
        window.dispatchEvent(failed_event);
      });
  }
}
