import ApplicationController from './application_controller';

export default class extends ApplicationController {

  targetElementSelector(){
    return '#new-packages';
  }

  add_empty(event) {
    var newPackages = document.querySelector(this.targetElementSelector())
    if (newPackages) {
      var templateInputsDiv = document.getElementById("template-new-package-inputs");
      var inputsDiv = templateInputsDiv.content.children[0].cloneNode(true);
      var inputNameEl = inputsDiv.querySelector('[id^=package_name]');
      var inputVersionEl = inputsDiv.querySelector('[id^=package_version]');
      var newPackageId = newPackages.querySelectorAll(".form.package").length;

      var keyName = 'new_packages[' + newPackageId + '][]';
      var keyVersion = 'new_packages[' + newPackageId + '][]';
      inputNameEl.setAttribute('name', keyName);
      inputNameEl.setAttribute('id', keyName);

      inputVersionEl.setAttribute('name', keyVersion);
      inputVersionEl.setAttribute('id', keyVersion);

      if (newPackages.firstChild){
        newPackages.insertBefore(inputsDiv, newPackages.firstChild);
      }else{
        newPackages.appendChild(inputsDiv);
      }
    }else{
      console.log('Broken form, please report. Cannot find ' + this.targetElementSelector())
    }
  }

  details(event){
    var packageVersionsEl = document.querySelector('.' + event.target.dataset.targetClass + '[id^="' + event.target.dataset.target + '"]');
    packageVersionsEl.classList.toggle('hidden');
  }
}
