import ModalController from './modal_controller';

export default class extends ModalController {

  static targets = ['accessRole'];

  show(event) {
    super.show(event);

    this.stimulate(
      'UserAccountReflex#show_access_modal',
      event.currentTarget,
      event.currentTarget.dataset.id
    )
  }

  destroyAccess(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    // Prevent double click.
    var target = event.currentTarget;
    var originalLabel = target.innerHTML;
    if (target.disabled === true) { return }
    target.disabled = true;
    target.innerHTML = event.currentTarget.dataset.stimulusDisableWith;
    let form = event.currentTarget.closest('form');
    let instPersonId = form.querySelector('#installations_person_id').value;

    this.stimulate('UserAccountReflex#destroy_access', target, instPersonId)
      .then(payload => {
        const success_event = new CustomEvent('save-indicator:success', { detail: payload });
        window.dispatchEvent(success_event);
      })
      .catch(payload => {
        const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
        window.dispatchEvent(failed_event);
      })
      .finally(() => {
        target.disabled = false;
        target.innerHTML = originalLabel;
      });
  }

  updateAccess(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    // Prevent double click.
    var target = event.currentTarget;
    var originalLabel = target.innerHTML;
    if (target.disabled === true) { return }
    target.disabled = true;
    target.innerHTML = event.currentTarget.dataset.stimulusDisableWith;
    let form = event.currentTarget.closest('form');
    let instPersonId = form.querySelector('#installations_person_id').value;
    let accessRole = null;
    let accessRoleForm = form.querySelector('#access_role');
    if (accessRoleForm != undefined) {
      accessRole = accessRoleForm.value;
    }

    this.stimulate('UserAccountReflex#update_access', target, instPersonId, document.querySelector('#locale').value, accessRole)
      .then(payload => {
        const success_event = new CustomEvent('save-indicator:success', { detail: payload });
        window.dispatchEvent(success_event);
      })
      .catch(payload => {
        const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
        window.dispatchEvent(failed_event);
      })
      .finally(() => {
        target.disabled = false;
        target.innerHTML = originalLabel;
      });

  }

  createAccess(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    // Prevent double click.
    var target = event.currentTarget;
    var originalLabel = target.innerHTML;
    if (target.disabled === true) { return }
    target.disabled = true;
    target.innerHTML = event.currentTarget.dataset.stimulusDisableWith;
    let form = event.currentTarget.closest('form');
    let instPersonId = form.querySelector('#installations_person_id').value;
    let userId = form.querySelector('#user_id');
    let accessRole = null;
    let accessRoleForm = form.querySelector('#access_role');
    if (accessRoleForm != undefined) {
      accessRole = accessRoleForm.value;
    }

    this.stimulate('UserAccountReflex#create_access', target, instPersonId, document.querySelector('#locale').value, userId == null ? null : userId.value, accessRole)
      .then(payload => {
        const success_event = new CustomEvent('save-indicator:success', { detail: payload });
        window.dispatchEvent(success_event);
      })
      .catch(payload => {
        const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
        window.dispatchEvent(failed_event);
      })
      .finally(() => {
        target.disabled = false;
        target.innerHTML = originalLabel;
      });
  }

  showAccessRoleDescription(event) {
    let form = event.currentTarget.closest('form');
    form.querySelectorAll('.access_role_description').forEach(function (descriptionLabel) {
      descriptionLabel.classList.add('hidden');
    });
    form.querySelector('[for="description_' + form.querySelector('#access_role').value + '"]').classList.remove('hidden');
  }
}
