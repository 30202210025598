import ApplicationController from './application_controller'
import tippy from 'tippy.js';
import {hideAll} from 'tippy.js';
import interact from 'interactjs';
import debounce from 'lodash/debounce'

export default class extends ApplicationController {

  static targets = ['template'];

  initialize() {
    this.setupTippy();
    this.setupInteract();
    this.timeout;
  }

  setupTippy() {
    this.popover = tippy(this.element, {
      allowHTML: true,
      appendTo: (reference) => {
        return document.querySelector('#floorplan-' + reference.dataset.floorplanId + ' .floorplan')  ;
      },
      content: this.templateTarget.innerHTML,
      interactive: true,
      placement: 'right',
      trigger: 'manual',
      hideOnClick: false,
      popperOptions: {
        strategy: 'fixed',
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: 'window',
            },
          }
        ]
      }
    });
  }

  showPopup(event) {
    event.stopPropagation();

    if (event.type === 'click') {
      if (this.popover.state.isShown === true) {
        this.popover.hide();
      } else {
        hideAll();
        this.popover.show();
      }
    } else if (this.placementController.toggledProductId == null) {
      if (event.type == 'mouseenter' && this.popover.state.isShown != true) {
        clearTimeout(this.timeout); //Clear first
        hideAll();
        this.timeout = setTimeout(() => {
          this.popover.show();
          clearTimeout(this.timeout);
        }, 750);
      }
      if (event.type == 'mouseleave') {
        clearTimeout(this.timeout);
      }


    }
  }

  setupInteract() {
    var controller = this;

    interact(this.element).draggable({
      listeners: {
        start() {
          controller.popover.disable();
        },
        end(event) {
          controller.stimulate('PlacementReflex#move', event.target)
          .then(payload => {
            controller.popover.enable();
            controller.popover.show();

            const success_event = new CustomEvent('save-indicator:success', { payload: payload });
            window.dispatchEvent(success_event);
          })
          .catch(payload => {
            const failed_event = new CustomEvent('save-indicator:failed', { payload: payload });
            window.currentTarget.dispatchEvent(failed_event);
          });
        },
        move(event) {
          var target = event.target;
          var overlayTarget = target.closest('.overlay')

          var x = ((parseFloat(target.dataset.x) || 0) * overlayTarget.clientWidth) - target.offsetWidth/2 + event.dx;
          var y = ((parseFloat(target.dataset.y) || 0) * overlayTarget.clientHeight) - target.offsetHeight/2 + event.dy;

          target.dataset.x = (x + target.offsetWidth/2) / overlayTarget.clientWidth;
          target.dataset.y = (y + target.offsetHeight/2) / overlayTarget.clientHeight;
          target.style.left = 'calc(' + target.dataset.x * 100 + '%' + ' - ' + target.offsetWidth/2 +'px)';
          target.style.top = 'calc(' + target.dataset.y * 100 + '%' + ' - ' + target.offsetHeight/2 +'px)';
        },
      },
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: 'parent',
        })
      ],
      cursorChecker: (action, interactable, element, interacting) => {
        return interacting ? 'grabbing' : 'grab';
      }
    });
  }

  get placementController() {
    return this.application.getControllerForElementAndIdentifier(this.element.closest('.proposal'), 'placement');
  }

}
