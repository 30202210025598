import ApplicationController from './application_controller';
import flatpickr from 'flatpickr';

export default class extends ApplicationController {

  static targets = ['expectedShipmentAt', 'dueAt', 'sendBefore', 'newDelivery'];

  assingFlatpickr(input, method) {
    var controller = this;
    flatpickr(input, {
      locale: this.getLocale(),
      dateFormat: 'd.m.Y',
      onChange: function (selectedDates, dateStr, instance) {
        controller[method](selectedDates, dateStr, instance);
      }
    });
  }

  initialize() {
    if (this.hasExpectedShipmentAtTarget) {
      this.flatpickrToAllDeliveryForms();
    }
  }

  flatpickrToAllDeliveryForms() {
    Array.from(document.querySelectorAll('input[name^="expected_installation_at"]')).forEach(input => this.assingFlatpickr(input, 'setExpectedShipmentAt'));
    Array.from(document.querySelectorAll('input[name^="send_before"]')).forEach(input => this.assingFlatpickr(input, 'setSendBefore'));
    Array.from(document.querySelectorAll('input[name^="due_at"]')).forEach(input => this.assingFlatpickr(input, 'setDueAt'));
  }

  setExpectedShipmentAt(_selectedDates, dateStr, flatpickr_instance) {
    event.preventDefault();
    this.stimulate('Delivery#set_expected_installation_at', flatpickr_instance.element, flatpickr_instance.element.dataset.id, dateStr).then(payload => { this.flatpickrToAllDeliveryForms() });
  }

  setSendBefore(_selectedDates, dateStr, flatpickr_instance) {
    event.preventDefault();
    this.stimulate('Delivery#set_send_before', flatpickr_instance.element, flatpickr_instance.element.dataset.id, dateStr).then(payload => { this.flatpickrToAllDeliveryForms() });
  }

  setDueAt(_selectedDates, dateStr, flatpickr_instance) {
    event.preventDefault();
    this.stimulate('Delivery#set_due_at', flatpickr_instance.element, flatpickr_instance.element.dataset.id, dateStr).then(payload => { this.flatpickrToAllDeliveryForms() });
  }

  setState(event) {
    event.preventDefault();
    this.stimulate('Delivery#set_state', event.target, event.target.dataset.id, event.target.value)
  }

  sendInvoice(event) {
    event.target.disabled = true;
     
    const invoiceErrorHandler = function(event) {
      alert(event.detail.errors.join("\n"));
    };
    document.addEventListener("invoiceError", invoiceErrorHandler, { once: true });
    event.preventDefault();
    this.stimulate('Delivery#send_invoice', event.target, event.target.dataset.id).then(() => {
      event.target.disabled = false;
    });
  }
}
