import ApplicationController from './application_controller';
export default class extends ApplicationController {

  initialize() {
    this.hidePerson();
  }

  hidePerson() {
    if (document.querySelector('body').dataset.userId) {
      document.querySelector('form #name').classList.add('hidden');
      document.querySelector('form #phone').classList.add('hidden');
      document.querySelector('form #email').classList.add('hidden');
    } else {
      console.log("Anonymouse should fill contact information.");
    }
  }
}
