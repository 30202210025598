import ApplicationController from './application_controller';
import throttle from 'lodash/throttle'

export default class extends ApplicationController {

  static targets = ['pagination', 'loadMore', 'list'];

  initialize() {
    this.loadMore = throttle(this.loadMore, 300)
    document.addEventListener('stimulus-reflex:connected', () => {
      this.loadMore(new Event('init'));
    })
  }

  // todo register after reflex to move target element
  reflexSuccess(element, reflex, noop, reflexId){
    var list = document.querySelector('.body #list');
    var cups = list.querySelectorAll('div.select-line');
    var currentCsrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    cups.forEach(function(cup) {
      list.parentElement.insertBefore(cup, list);
      var csrfInput = cup.querySelector('input[name="authenticity_token"]');
      if (csrfInput != undefined) {
        cup.querySelector('input[name="authenticity_token"]').setAttribute('value', currentCsrfToken);
      }
      currentCsrfToken
    });

  }


  loadMore(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (this.hasLoadMoreTarget) {
      var outset = parseInt(this.loadMoreTarget.dataset.outset);
      var step = parseInt(this.loadMoreTarget.dataset.step);
      var target = this.loadMoreTarget;
    } else {
      var outset = parseInt(this.paginationTarget.dataset.outset);
      var step = parseInt(this.paginationTarget.dataset.step);
      var target = this.paginationTarget;
    }

    this.stimulate(
      'CentralUnitPackagesReflex#search',
      target,
      outset,
      step,
    )
  }
}
