import ToggleElementController from "./toggle_element_controller";

export default class extends ToggleElementController {
  toggleOne(event) {
    super.toggleOne(event);
    const approveManufacturing = document.getElementById('approve_manufacturing');
    if (approveManufacturing) {
      approveManufacturing.classList.toggle('hidden');
    }
  }
}
