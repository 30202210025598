import ModalController from './modal_controller'

export default class extends ModalController {

  changeQuantity(event) {
    event.target.disabled = true; // The element is enabled again after redraw by Reflex automatically.

    this.stimulate(
      'ProposalSaleReflex#change_quantity',
      event.target,
      this.element.dataset.saleId,
      event.target.value,
    )
    .then(payload => {
      const success_event = new CustomEvent('save-indicator:success', { detail: payload });
      window.dispatchEvent(success_event);
    })
    .catch(payload => {
      event.target.classList.add('reflex-error');
      const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
      window.dispatchEvent(failed_event);
    });
  }

  changeUnitPrice() {
    event.target.disabled = true; // The element is enabled again after redraw by Reflex automatically.

    this.stimulate(
      'ProposalSaleReflex#change_unit_price',
      event.target,
      this.element.dataset.saleId,
      this.element.dataset.proposalId,
      event.target.value,
    )
    .then(payload => {
      const success_event = new CustomEvent('save-indicator:success', { detail: payload });
      window.dispatchEvent(success_event);
    })
    .catch(payload => {
      event.target.classList.add('reflex-error');
      const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
      window.dispatchEvent(failed_event);
    });
  }

  changeDiscount(event) {
    event.target.disabled = true; // The element is enabled again after redraw by Reflex automatically.

    this.stimulate(
      'ProposalSaleReflex#change_discount',
      event.target,
      this.element.dataset.saleId,
      this.element.dataset.proposalId,
      event.target.value,
    )
    .then(payload => {
      const success_event = new CustomEvent('save-indicator:success', { detail: payload });
      window.dispatchEvent(success_event);
    })
    .catch(payload => {
      event.target.classList.add('reflex-error');
      const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
      window.dispatchEvent(failed_event);
    });
  }

  changeVatRateModal(event) {
      super.show(event);
  
      this.stimulate(
        'ProposalSaleReflex#show_edit_vatrate',
        event.currentTarget,
      )
  }

}
