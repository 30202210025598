import ApplicationController from './application_controller'

export default class extends ApplicationController {
    addHwPartCount(event) {
        event.preventDefault()
        this.stimulate('StockReflex#add_hw_part_count', event.target).then(() => {
            const hwPartCount = event.target.parentElement.parentElement.querySelector('.hw-part-count')
            const countInput = event.target.querySelector('.count-input')
            hwPartCount.innerHTML = parseInt(hwPartCount.innerHTML) + parseInt(countInput.value)
            countInput.value = ''
        })
    }

    deleteHwPart(event) {
        event.preventDefault()
        if (!confirm(event.target.dataset.confirmMsg)) {
            return
        }
        this.stimulate('StockReflex#delete_hw_part', event.target, event.target.dataset.hwPartId).then(() => {
            const rowTodelete = event.target.closest('tr')
            rowTodelete.remove()
        });
    }
}