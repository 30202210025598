import ToggleElementController from "./toggle_element_controller";

export default class extends ToggleElementController {
  toggle_address(e) {
    console.log(e.target.dataset);
    const addressSection = document.getElementById(
      e.target.dataset.toggleElement
    );
    const formCheck = document.querySelector(e.target.dataset.formCheck);
    addressSection.classList.toggle("hidden");
    addressSection.classList.toggle("expanded");
    formCheck.scrollIntoView({ behavior: "smooth", block: "end" });
  }
}
