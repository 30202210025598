import ModalController from '../modal_controller';
import Rails from "@rails/ujs"

export default class extends ModalController {

  static targets = ['pagination', 'form', 'loadMore', 'search', 'monitorable_type'];

  editContactableModal(event) {
    super.show(event);

    this.stimulate(
      'Monitoring::TemplateSettings#show_edit_contactable',
      event.currentTarget,
    )
  }

  editContactableGroupModal(event) {
    super.show(event);

    this.stimulate(
      'Monitoring::TemplateSettings#show_edit_group_contactable',
      event.currentTarget,
    )
  }

  addToAllInstallationsModal(event) {
    super.show(event);

    this.stimulate(
      'Monitoring::TemplateSettings#show_spread_person_to_installations',
      event.currentTarget,
    )
  }

  destroyContactableModal(event) {
    super.show(event);

    this.stimulate(
      'Monitoring::TemplateSettings#show_destroy_contactable',
      event.currentTarget,
    )
  }

  editSendibleModal(event) {
    super.show(event);

    this.stimulate(
      'Monitoring::TemplateSettings#show_edit_sendible',
      event.currentTarget,
    )
  }

  editSendibleGroupModal(event) {
    super.show(event);

    this.stimulate(
      'Monitoring::TemplateSettings#show_edit_group_sendible',
      event.currentTarget,
    )
  }

  setIcon(event) {
    document.querySelector('span#' + event.currentTarget.id).setAttribute('class', 'notify ' + event.currentTarget.options[event.currentTarget.selectedIndex].value)
  }

  loadMore(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.spinnerOn();
    this.setRequestId();
    this.setActionSource(event.srcElement.id);
    Rails.fire(this.formTarget, 'submit');
  }

  result(event) {
    let requestId = event.detail[0].body.querySelector('#request_id');
    if (requestId != undefined && this.requestId == requestId.value) {
      let actionSource = event.detail[0].body.querySelector('#action_source').value;
      var list = document.querySelector('.body #list');
      if (actionSource == 'search' || actionSource == 'monitorable_type' || actionSource == '' || actionSource == undefined) {
        var templateSettingsList = event.detail[0].body.querySelector('body #list');
        list.parentElement.replaceChild(templateSettingsList, list);
      } else if (actionSource == 'pagination-link') {
        var templateSettingsList = event.detail[0].body.querySelectorAll('div.template-setting');
        templateSettingsList.forEach(function (templateSetting) {
          list.appendChild(templateSetting);
        });
      }
      this.replaceElementWithNewBySingleQuery('div#pagination', event)
      this.replaceElementWithNewBySingleQuery('#outset', event)
      this.replaceElementWithNewBySingleQuery('#step', event)
      this.spinnerOff();
    }
  }
}
