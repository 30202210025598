import ModalController from './modal_controller';
import flatpickr from 'flatpickr';
import { Czech } from "flatpickr/dist/l10n/cs.js"

export default class extends ModalController {

  static targets = ['postponedUntil'];

  initialize() {
    let locale = document.querySelector('#locale')
    if (locale == 'en') {
      locale = Eng;
    } else {
      locale = Czech;
    }
    if (this.hasPostponedUntilTarget) {
      var controller = this;
      flatpickr(this.postponedUntilTarget, {
        locale: locale,
        dateFormat: 'd.m.Y',
        onChange: function (selectedDates, dateStr, instance) {
          controller.setPostponeUntil(selectedDates, dateStr, instance);
        }
      });
    }
  }

  setPostponeUntil(_selectedDates, dateStr, flatpickr_instance) {
    this.stimulate('ProposalQuick#postpone_until', flatpickr_instance.element, flatpickr_instance.element.dataset.id, dateStr)
  }

  setComment(event) {
    event.preventDefault();
    this.stimulate('ProposalQuick#set_comment', event.target, event.target.dataset.id, event.target.value)
  }

  setState(event) {
    event.preventDefault();
    this.stimulate('ProposalQuick#set_state', event.target, event.target.dataset.id, event.target.value)
  }

  changeDelivery(event) {
    event.preventDefault();

    this.stimulate('ProposalQuick#change_delivery', event.target, event.target.dataset.saleId, this.getProposalId(event.target), event.target.value)
  }

  changeUnitPrice(event) {
    event.preventDefault();
    let eTarget = event.target;
    this.stimulate('ProposalQuick#change_unit_price', eTarget, eTarget.dataset.saleId, this.getProposalId(eTarget), eTarget.value);
  }

  changeDiscount(event) {
    event.preventDefault();
    let eTarget = event.target;
    this.stimulate('ProposalQuick#change_discount', eTarget, eTarget.dataset.saleId, this.getProposalId(eTarget), eTarget.value);
  }

  createDelivery(event) {
    event.preventDefault();
    this.stimulate('ProposalQuick#create_delivery', event.target, this.getProposalId(event.target.closest('.proposal-tab-content'))).then(
      _ => { this.toggleActionsElements(event.target.closest('.proposal-tab-content').querySelector('.body input[type=checkbox]')); }
    );
  }

  destroySale(event) {
    event.preventDefault();
    let eTarget = event.target;
    this.stimulate('ProposalQuick#destroy_sale', eTarget, eTarget.dataset.id, this.getProposalId(eTarget));
  }

  selectAll(event) {
    event.preventDefault();
    let checkboxes = this.getAllCheckboxes(event.target);
    checkboxes.forEach(function (checkbox) {
      checkbox.checked = event.target.checked;
    });
    this.toggleActionsElements(event.target);
  }

  toggleActions(event) {
    this.toggleActionsElements(event.target);
  }

  selectSame(event) {
    event.preventDefault();
    let tableBody = event.target.closest('.proposal-sales');
    let checkboxes = tableBody.querySelectorAll('input[type=checkbox][data-product-id=\'' + event.target.dataset.target + '\']');
    checkboxes.forEach(function (checkbox) {
      checkbox.checked = event.target.checked;
    });
    this.toggleActionsElements(event.target);
  }

  showConfirmModal(event) {
    this.show(event);
    this.stimulate(
      'ProposalQuick#show_confirm_form',
      event.currentTarget,
      event.target.dataset.targetId
    );
  }

  showSpinner(proposalId){
    var spinnerWrapper = document.querySelector('#proposal-grouped-sales-' + proposalId + ' #spinner');
    this.spinnerOn(spinnerWrapper);
  }

  destroySelected(event) {
    event.preventDefault();
    let targetId = event.target.closest('.button').dataset.targetId;
    let proposalDiv = document.querySelector('#' + targetId);
    let checkboxes = this.getSelectedCheckboxes(proposalDiv);
    let proposalId = this.getProposalId(checkboxes[0]);
    this.showSpinner(proposalId);

    var controller = this;
    controller.stimulate('ProposalQuick#destroy_sale',
      event.target,
      Array.from(checkboxes).map(checkbox => checkbox.dataset.target),
      proposalId
    ).then(_ => {
      controller.toggleActionsElements(proposalDiv);
      this.closeModal();
    });
  }

  changeDeliverySelected(event) {
    event.preventDefault();

    let checkboxes = this.getSelectedCheckboxes(event.target)
    let proposalId = this.getProposalId(checkboxes[0]);
    this.showSpinner(proposalId);
    var controller = this;

    controller.stimulate('ProposalQuick#change_delivery',
      event.target,
      Array.from(checkboxes).map(checkbox => checkbox.dataset.target),
      proposalId,
      event.target.value
    ).then(_ => {
      controller.toggleActionsElements(event.target);
    });
  }
  changeUnitPriceSelected(event) {
    event.preventDefault();
    let checkboxes = this.getSelectedCheckboxes(event.target)
    let proposalId = this.getProposalId(checkboxes[0]);
    this.showSpinner(proposalId);
    var controller = this;

    controller.stimulate('ProposalQuick#change_unit_price',
      event.target,
      Array.from(checkboxes).map(checkbox => checkbox.dataset.target),
      proposalId,
      event.target.value
    ).then(_ => {
      controller.toggleActionsElements(event.target);
    });
  }

  changeDiscountSelected(event) {
    event.preventDefault();
    let checkboxes = this.getSelectedCheckboxes(event.target)
    let proposalId = this.getProposalId(checkboxes[0]);
    this.showSpinner(proposalId);
    var controller = this;

    controller.stimulate('ProposalQuick#change_discount',
      event.target,
      Array.from(checkboxes).map(checkbox => checkbox.dataset.target),
      proposalId,
      event.target.value
    ).then(_ => {
      controller.toggleActionsElements(event.target);
    });
  }

  destroyDelivery(event) {
    event.preventDefault();
    var controller = this;
    let proposalContent = event.target.closest('.proposal-tab-content').querySelector('.body input[type=checkbox]');
    this.stimulate('ProposalQuickReflex#destroy_delivery', event.target, event.target.dataset.id).then(_ => {
      controller.toggleActionsElements(proposalContent);
    });
  }

  toggleActionsElements(tableElement) {
    let tableBody = tableElement.closest('.proposal-sales');
    let actionDivs = tableBody.querySelectorAll('.actions div')
    let checkboxes = this.getSelectedCheckboxes(tableElement);
    if (checkboxes.length > 0) {
      if (actionDivs.item(0).classList.contains('hidden')) {
        actionDivs.forEach(function (actionDiv) {
          actionDiv.classList.remove('hidden');
          actionDiv.classList.add('flex');
        });
      }
    } else {
      if (actionDivs.item(0).classList.contains('flex')) {
        actionDivs.forEach(function (actionDiv) {
          actionDiv.classList.remove('flex');
          actionDiv.classList.add('hidden');
        });
      }
    }
  }

  getAllCheckboxes(tableElement) {
    let tableBody = tableElement.closest('.proposal-sales');
    return tableBody.querySelectorAll('.body input[type=checkbox]');
  }

  getSelectedCheckboxes(tableElement) {
    let tableBody = tableElement.closest('.proposal-sales');
    return tableBody.querySelectorAll('.body input[type=checkbox][data-type=sale]:checked');
  }

  getProposalId(eventElement){
    let body = eventElement.closest('.body');
    if (body != undefined) {
      return body.dataset.proposalId;
    }
    return eventElement.querySelector('.body').dataset.proposalId;
  }
}
