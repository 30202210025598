import ApplicationController from './application_controller';

export default class extends ApplicationController {

  static targets = ['personFields', 'input', 'hidden']

  connect() {
    this.changePerson();
    document.addEventListener("autocomplete.change", this.changePerson.bind(this));
    super.connect();
  }

  changePerson() {
    if (!this.hasInputTarget) { return }

    if (event.detail && event.detail.value != "") {
      document.querySelector('input.autocomplete#person_id').value = event.detail.value;
    }

    var disabled;
    if (this.inputTarget.value.trim() === "") {
      this.personFieldsTarget.style.display = 'contents';
      disabled = false;
    } else {
      this.personFieldsTarget.style.display = 'none';
      disabled = true;
    }

    // Don't send hidden input to the server. Otherwise it creates a new record instead of assigning the selected one.
    var inputs = this.personFieldsTarget.getElementsByTagName('input');
    for (let input of inputs) { input.disabled = disabled; };
  }

}
