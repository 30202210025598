import ApplicationController from './application_controller';
import tippy from 'tippy.js';

export default class extends ApplicationController {

  static targets = ['template']

  initialize() {
    tippy(this.element, {
      allowHTML: true,
      interactive: true,
      content: this.templateTarget.innerHTML,
      placement: 'top',
      appendTo: 'parent',
    });
  }

}
