import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["percentAmount", "selectedDelivery", "percentAmountInput", "deliveryIdSelect", "vatRate", "submitButton", "invoiceForm", "applyAdvance", "morphPage", "vatRateTypeSelect", "differentVatIndicator"];

  initialize() {
    this.debounceTimeout = null;
  }

  connect() {
    super.connect();
    this.stimulate("CreateInvoiceReflex#show_invoice_items", this.invoiceFormTarget);
  }

  submitSetCustomer(event) {
    event.preventDefault();
    this.stimulate("CreateInvoiceReflex#set_customer", event.target);
  }

  copyInvoiceAddressFromCustomer(event) {
    event.preventDefault();
    this.stimulate("CreateInvoiceReflex#copy_invoice_address_from_customer", event.target);
  }

  handleInvoiceTypeChange(event) {
    if (event.target.value === "advance_invoice") {
      this.percentAmountTarget.classList.remove("hidden");
      this.selectedDeliveryTarget.classList.add("hidden");
      this.deliveryIdSelectTarget.value = "";
      this.applyAdvanceTarget.classList.add("hidden");
    } else {
      this.percentAmountTarget.classList.add("hidden");
      this.selectedDeliveryTarget.classList.remove("hidden");
      this.percentAmountInputTarget.value = "";
      this.applyAdvanceTarget.classList.remove("hidden");
    }

    this.showInvoiceItems(event);
  }

  showInvoiceItems(event) {
    event.preventDefault();
    this.stimulate("CreateInvoiceReflex#show_invoice_items", event.target);
  }

  debounceShowInvoiceItems(event) {
    event.preventDefault();

    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      this.showInvoiceItems(event);
    }, 1000);
  }

  handleVatRateChange(event) {
    event.preventDefault();
    const proposalVatRate = this.differentVatIndicatorTarget.dataset.proposalVatRate;
    const selectedOptionText = event.target.selectedOptions[0].text;
    if (selectedOptionText === proposalVatRate) {
      this.differentVatIndicatorTarget.classList.add("hidden");
    } else {
      this.differentVatIndicatorTarget.classList.remove("hidden");
    }
    this.vatRateTarget.value = selectedOptionText;
    this.showInvoiceItems(event);
  }

  handleApplyAdvanceChange(event) {
    event.preventDefault();
    this.showInvoiceItems(event);
  }

  submitInvoice(event) {
    event.preventDefault();
    document.addEventListener("invoiceSubmitted", (event) => {
      const invoiceForm = document.getElementById("send_pohoda_invoice");
        if (invoiceForm) {
          invoiceForm.classList.add("hidden");
        }
    } , { once: true });
    const morphPage = this.morphPageTarget.value;
    const spinnerElement = this.createSpinnerElement();
    const viewportHeight = window.innerHeight;
    const spinnerHeight = spinnerElement.offsetHeight;
    const middlePosition = (viewportHeight - spinnerHeight) / 2 + window.scrollY;

    spinnerElement.style.top = `${middlePosition}px`;
    document.body.appendChild(spinnerElement);
    document.body.style.opacity = 0.7;
    this.spinnerOn(spinnerElement);
    this.submitButtonTarget.disabled = true;
    this.stimulate("CreateInvoiceReflex#submit_invoice", event.target, morphPage).then(() => {
      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.disabled = false;
      }
      this.spinnerOff(spinnerElement);
      document.body.style.opacity = 1;
      window.scrollTo(0, 0);
    });
  }

  handleManualVatInfoChange(event) {
    event.preventDefault();
    this.vatRateTypeSelectTarget.disabled = !this.vatRateTypeSelectTarget.disabled;
  }
}
