import ApplicationController from './application_controller';

export default class extends ApplicationController {

  static targets = ['nav'];

  clickHandler(event) {
    event.currentTarget.classList.toggle('change');

    this.navTarget.classList.toggle('shown');
  }
}
