import ModalController from './modal_controller';
import flatpickr from 'flatpickr';

export default class extends ModalController {

  static targets = ["discountsFor", "proposalId", "priceListId", "paymentFor"];
  connect() {
    document.addEventListener("autocomplete.change", this.addTag.bind(this));
    super.connect();
  }

  showChangeSale(event) {
    super.show(event);

    this.stimulate(
      'InstallationReflex#show_change_sale',
      event.currentTarget,
      event.currentTarget.dataset.saleId
    )
  }

  showSalesAreas(event) {
    super.show(event);

    this.stimulate(
      'InstallationReflex#show_change_sales_area_modal',
      event.currentTarget,
    );
  }

  changeSale(event) {
    event.preventDefault();
    let form = event.target.closest('form');

    let saleId = form.querySelector('input#sale_id').value;
    let productId = form.querySelector('select#product_id').value;
    let cableProductId = null;
    let cableProductElement = form.querySelector('select#cable_product_id');
    if (form.querySelector('select#cable_product_id') != undefined) {
      cableProductId = cableProductElement.value;
    }

    let bindDevicesInput = form.querySelector('select#bind_devices')
    let bindDevices = false;
    if (bindDevicesInput != undefined) {
      bindDevices = bindDevicesInput.value;
    }

    let proposalId = form.querySelector('select#proposal_id').value;
    let replacementTypeId = form.querySelector('select#replacement_type_id').value;
    let delivered = form.querySelector('select#delivered').value;
    let returnedProduct = form.querySelector('select#returned_product').value;
    let addCable = null; 
    if (form.querySelector('select#add_cable') != undefined ) {
      addCable = form.querySelector('select#add_cable').value;
    }

    this.spinnerOn();

    this.stimulate('InstallationReflex#change_sale',
      event.target,
      saleId,
      productId,
      cableProductId,
      replacementTypeId,
      delivered,
      returnedProduct,
      proposalId,
      bindDevices,
      addCable
    ).then(_payload => location.reload());
  }

  showApplyPriceListModal(event) {
    super.show(event);

    this.stimulate(
      'InstallationReflex#show_apply_price_list',
      event.target
    )
  }

  showPriceListFor(event) {
    let spinnerDiv = event.srcElement.closest('form').querySelector('#spinner');
    let controller = this;
    this.spinnerOn(spinnerDiv);
    this.stimulate(
      'InstallationReflex#show_price_list',
      event.target,
      this.hasPriceListIdTarget ? document.querySelector('#price_list_id').value : null,
    ).finally(() => { controller.spinnerOff(spinnerDiv); });
  }

  showDiscountsFor(event) {
    let spinnerDiv = event.srcElement.closest('form').querySelector('#spinner');
    let controller = this;
    this.spinnerOn(spinnerDiv);
    this.stimulate(
      'InstallationReflex#show_discounts',
      event.target,
      this.hasDiscountsForTarget ? document.querySelector('#discounts_for').value : null,
    ).finally(() => { controller.spinnerOff(spinnerDiv); });
  }

  applyPriceList(event) {
    let spinnerDiv = event.srcElement.closest('form').querySelector('#spinner');
    let controller = this;
    this.spinnerOn(spinnerDiv);
    this.stimulate(
      'InstallationReflex#apply_price_list',
      event.target,
      this.hasPriceListIdTarget ? document.querySelector('#price_list_id').value : null,
      this.hasProposalIdTarget ? document.querySelector('#proposal_id').value : null
    ).then(_payload => { location.reload(); } ).finally(() => { controller.spinnerOff(spinnerDiv); });
  }

  applyDiscount(event) {
    let spinnerDiv = event.srcElement.closest('form').querySelector('#spinner');
    let controller = this;
    this.spinnerOn(spinnerDiv);
    this.stimulate(
      'InstallationReflex#apply_discount',
      event.target,
      this.hasDiscountsForTarget ? document.querySelector('#discounts_for').value : null,
      this.hasProposalIdTarget ? document.querySelector('#proposal_id').value : null
    ).then(_payload => { location.reload(); }).finally(() => { controller.spinnerOff(spinnerDiv); });
  }

  showApplyDiscountModal(event) {
    super.show(event);

    this.stimulate(
      'InstallationReflex#show_apply_discount',
      event.target
    )
  }

  showTagModal(event) {
    super.show(event);

    this.stimulate(
      'InstallationReflex#show_tags_form',
      event.target
    ).then(payload => {
      document.querySelector('#add_tag').value = '???';
      document.querySelector('#add_tag').dispatchEvent(new Event("input", { bubbles: true }));
    })
  }

  showPlacementsTagModal(event) {
    super.show(event);

    const placementId = event.target.dataset.placementId;

    console.log("placementId", placementId);

    this.stimulate(
      "InstallationReflex#show_placements_tags_form",
      event.target,
      placementId
    ).then((payload) => {
      document.querySelector("#add_tag").value = "???";
      document
        .querySelector("#add_tag")
        .dispatchEvent(new Event("input", { bubbles: true }));
    });
  }

  findTagIds(event) {
    let allTagsHidden = event.srcElement.closest('form').querySelectorAll('[name="tags[]"]')
    let tagIds = new Array();
    allTagsHidden.forEach((hiddenTagId) => {
      tagIds.push(hiddenTagId.value);
    });
    return tagIds;
  }

  addTag(event) {
    if (event.srcElement.id == "add_tag") {
      if (event.srcElement.querySelector('input').value != '') {
        event.srcElement.querySelector('input').value = '';
        event.preventDefault();
        event.stopImmediatePropagation();
        let tagIds = this.findTagIds(event);
        tagIds.push(event.detail.value);

        this.stimulate('InstallationReflex#update_tags_in_modal', event.target, tagIds).catch(payload => {
          const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
          window.dispatchEvent(failed_event);
        });
      } else {
        event.stopImmediatePropagation();
      }
    }
  }

  removeTag(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    let tagIds = this.findTagIds(event);

    tagIds = tagIds.filter(function (value, index, arr) {
      return value != event.srcElement.dataset.tagId
    });

    this.stimulate('InstallationReflex#update_tags_in_modal', event.target, tagIds).catch(payload => {
      const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
      window.dispatchEvent(failed_event);
    });
  }

  saveTags(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    let tagIds = this.findTagIds(event);

    this.callSaveTagsReflex(
      "InstallationReflex#save_tags",
      event.target,
      tagIds
    );
  }

  savePlacementsTags(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const tagIds = this.findTagIds(event);
    const placementId = event.target.dataset.placementId;

    this.callSaveTagsReflex(
      "InstallationReflex#save_placements_tags",
      event.target,
      tagIds,
      placementId,
    );
  }

  callSaveTagsReflex(reflex, target, ...args) {
    this.stimulate(reflex, target, ...args)
      .then((payload) => {
        this.closeModal();
        const success_event = new CustomEvent("save-indicator:success", {
          detail: payload,
        });
        window.dispatchEvent(success_event);
      })
      .catch((payload) => {
        const failed_event = new CustomEvent("save-indicator:failed", {
          detail: payload,
        });
        window.dispatchEvent(failed_event);
      });
  }
}
