import ApplicationController from './application_controller';

export default class extends ApplicationController {

  static targets = ['canvas', 'chosenType', 'chooseButton', 'rotation', 'rotateButton', 'spinner', 'pageNumber', 
    'wrapper', 'chooseHeader', 'controlsHeader', 'floorNumberInput', 'error']

  initialize() {
    this.rendering = false;
    this.conccurrentPages = 4;
    this.pageNumber = parseInt(this.pageNumberTarget.value) + 1;
    this.index = parseInt(this.element.dataset.index);

    // Initiate download as soon as possible to get proper total size for the progress bar.
    this.pdfJob = this.pdfSelectorController.loadPDF(this.element.dataset.fileUrl, this.element.dataset.fileId);

    if (this.index === 0) {
      var rotation = parseInt(this.rotationTarget.value) || 0;
      this.renderPage(1, this.canvasTarget, rotation);
    }
  }

  /**
   * Listen to 'pageRendered' event. If this page is not rendering yet and its index is close (`conccurrentPages`) to
   * the just rendered page, start rendering this page too.
   */
  renderedListener(event) {
    if (this.rendering === true) {
      return;
    }

    if (parseInt(event.detail.index) + this.conccurrentPages >= this.index) {
      var rotation = parseInt(this.rotationTarget.value) || 0;
      this.renderPage(this.pageNumber, this.canvasTarget, rotation);
    }
  }

  renderPage(pageNumber, canvas, rotation) {
    this.rendering = true;
    this.spinnerTarget.style.display = 'block';

    this.pdfJob.then((pdf) => {
      var page = pdf.getPage(pageNumber);

      page.then((page) => {
        this.rotationTarget.value = rotation;
        var scale = 1.0;
        var viewport = page.getViewport({ scale: scale, rotation: rotation + page.rotate });

        if (viewport.height > viewport.width) {
          scale = this.wrapperTarget.clientHeight / viewport.height;
        } else {
          scale = this.wrapperTarget.clientWidth / viewport.width;
        }

        var viewport = page.getViewport({ scale: scale, rotation: rotation + page.rotate });

        var context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        var renderContext = {
          canvasContext: context,
          viewport: viewport,
        }

        var renderTask = page.render(renderContext);
        renderTask.promise.then(() => {
          console.log('Page rendered.');
          const renderedEvent = new CustomEvent(
            'pageRendered',
            { detail: { index: this.index } }
          );
          window.dispatchEvent(renderedEvent);
          this.spinnerTarget.style.display = 'none';
          for (const button of this.rotateButtonTargets) {
            button.disabled = false;
          }
        });
      });
    });
  }

  choosePlan() {
    event.preventDefault();
    this.element.classList.add('selected');
  }

  unchoosePlan() {
    event.preventDefault();
    this.floorNumberInputTarget.value = '';
    if (this.hasErrorTarget) {
      this.errorTarget.remove();
    }
    var field = this.floorNumberInputTarget.closest('.field_with_errors');
    if (field) {
      field.classList.remove('field_with_errors');
    }
    this.element.classList.remove('selected');
  }

  chooseType(event) {
    this.chosenTypeTarget.value = event.target.value;
    for (const button of this.chooseButtonTargets) {
      button.classList.remove('btn-success');
      button.classList.add('btn-secondary');
    }
    event.target.classList.remove('btn-secondary');
    event.target.classList.add('btn-success');
  }

  rotateLeft() {
    for (const button of this.rotateButtonTargets) { button.disabled = true; }
    var rotation = parseInt(this.rotationTarget.value) - 90;
    this.renderPage(parseInt(this.pageNumber), this.canvasTarget, rotation);
  }

  rotateRight() {
    for (const button of this.rotateButtonTargets) { button.disabled = true; }
    var rotation = parseInt(this.rotationTarget.value) + 90;
    this.renderPage(parseInt(this.pageNumber), this.canvasTarget, rotation);
  }

  get pdfSelectorController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element.closest('form'),
      'pdf-selector'
    );
  }

}
