import ApplicationController from './application_controller';
import flatpickr from 'flatpickr';

export default class extends ApplicationController {

  initialize() {
    document.addEventListener('stimulus-reflex:connected', () => {
      this.confirmAlert(new Event('confirm'));
    })
  }

  confirmAlert(event) {
    this.stimulate('Monitoring::AlertConfirmationReflex#confirm');
  }

}
