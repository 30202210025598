import ApplicationController from './application_controller';

export default class extends ApplicationController {

  static targets = ['checkBox']

  initialize() {
    let rawSettings = this.getCookie('erp_settings');
    this.erpSettings = JSON.parse(rawSettings);
  }

  toggle(event) {
    let settingsName = this.checkBoxTarget.id;
    let currentSettings = this.erpSettings;
    settingsName.split('.').forEach(function (key, index, arr) {
      if (index + 1 == arr.length) {
        currentSettings[key] = !currentSettings[key];
      } else {
        currentSettings = currentSettings[key];
      }
    });
    this.setCookie('erp_settings', JSON.stringify(this.erpSettings))
  }


  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setCookie(cname, cvalue) {
    document.cookie = cname + "=" + cvalue + ";path=/";
  }
}
