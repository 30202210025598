import ModalController from "./modal_controller";

export default class extends ModalController {
  show(event) {
    super.show(event);

    this.stimulate(
      (event.target.dataset.showReflexClass || "AddressReflex") +
        "#show_address_modal",
      event.currentTarget
    );
  }

  submitAddress(event) {
    event.preventDefault();

    // Prevent double click.
    var target = event.currentTarget;
    var originalLabel = target.innerHTML;
    if (target.disabled === true) {
      return;
    }
    target.disabled = true;
    target.innerHTML = event.currentTarget.dataset.stimulusDisableWith;

    this.stimulate(
      event.target.dataset.submitReflexClass + "#submit_address_modal",
      event.target
    )
      .then((payload) => {
        this.closeModal();
        const success_event = new CustomEvent("save-indicator:success", {
          detail: payload,
        });
        window.dispatchEvent(success_event);
      })
      .catch((payload) => {
        const failed_event = new CustomEvent("save-indicator:failed", {
          detail: payload,
        });
        window.dispatchEvent(failed_event);
      })
      .finally(() => {
        target.disabled = false;
        target.innerHTML = originalLabel;
      });
  }

  destroyAddress(event) {
    event.preventDefault();

    if (confirm(event.currentTarget.dataset.stimulusConfirm)) {
      this.stimulate(
        "AddressReflex#destroy_address",
        event.target,
        event.currentTarget.dataset.id
      )
        .then((payload) => {
          this.closeModal();
          const success_event = new CustomEvent("save-indicator:success", {
            detail: payload,
          });
          window.dispatchEvent(success_event);
        })
        .catch((payload) => {
          const failed_event = new CustomEvent("save-indicator:failed", {
            detail: payload,
          });
          window.dispatchEvent(failed_event);
        });
    }
  }

  contactableChanged(event) {
    const addressName = document.getElementById("address_name");
    const selectedOption = event.target.selectedOptions[0]?.text;
    if (selectedOption) {
      addressName.value = selectedOption;
    } else {
      addressName.value = "";
    }
  }
}
