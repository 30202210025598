import ModalController from './modal_controller';

export default class extends ModalController {
  show(event) {
    super.show(event);

    this.stimulate(
      'AlertRuleReflex#show',
      event.currentTarget,
      event.currentTarget.dataset.id,
      event.currentTarget.dataset.alertRuleGroupName,
      event.currentTarget.dataset.ruleAction,
      event.currentTarget.dataset.alertRuleId || 0,
    )
  }

  applyAlertRuleAction(event){

    this.stimulate(
      'AlertRuleReflex#apply_alert_rule_action',
      event.currentTarget,
      event.currentTarget.dataset.id,
      event.currentTarget.dataset.alertRuleGroupName,
      event.currentTarget.dataset.ruleAction,
      event.currentTarget.dataset.alertRuleId || 0,
      {
        alert: event.currentTarget.parentElement.querySelector('input#alert').value,
        expr: event.currentTarget.parentElement.querySelector('input#expr').value,
        for: event.currentTarget.parentElement.querySelector('input#for').value,
        evaluate_interval: event.currentTarget.parentElement.querySelector('input#evaluate_interval').value,
        labels: { severity: event.currentTarget.parentElement.querySelector('select#labels_severity').value }
      }
    ).then(payload => {
      this.closeModal();
      const success_event = new CustomEvent('save-indicator:success', { detail: payload });
      window.dispatchEvent(success_event);
    })
    .catch(payload => {
      const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
      window.dispatchEvent(failed_event);
    })
    .finally(() => {
    });
  }
}
