import ApplicationController from './application_controller';

export default class extends ApplicationController {

  static targets = ['tab', 'tabContent', 'handlers'];

  initialize() {
    this.documentReady(this.showActiveTab.bind(this));
  }

  activate(event) {
    event.preventDefault();
    var target = event.currentTarget;
    if (document.getElementById(target.dataset.tabTarget)) {
      for (const tab of this.tabTargets) {
        if (tab.dataset.tabTarget === target.dataset.tabTarget) {
          tab.classList.add('active');
        } else {
          tab.classList.remove('active');
        }
      }
      for (const tabContent of this.tabContentTargets) {
        if (tabContent.id === target.dataset.tabTarget) {
          tabContent.style.display = this.element.dataset.display || 'block';
        } else {
          tabContent.style.display = 'none';
        }
      }
    } else {
      this.appendLog('Cannot find element with id ' + target.dataset.tabTarget);
    }
  }

  /*
   * Calculates width of a tab handler including its width, padding, border and also margin.
   */
  calcTabWidth() {
    var width = this.tabTarget.offsetWidth;
    var style = getComputedStyle(this.tabTarget);
    var total = width + parseInt(style.marginLeft) + parseInt(style.marginRight);

    return total;
  }

  showActiveTab () {
    document.querySelectorAll('ul li[data-target=\'tabs.tab\']').forEach(function(tabElement) {
      let tabContent = document.querySelector('#' + tabElement.dataset.tabTarget);
      if (tabElement.classList.contains('active')) {
        tabContent.style.display = 'block';
      } else {
        tabContent.style.display = 'none';
      }
    });
  }

  toLeft() {
    this.handlersTarget.scrollLeft -= this.calcTabWidth();
  }

  toRight() {
    this.handlersTarget.scrollLeft += this.calcTabWidth();
  }
}
