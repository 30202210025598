import ApplicationController from './application_controller';
import Rails from "@rails/ujs"
export default class extends ApplicationController {

  static targets = ['pagination', 'form', 'loadMore', 'search', 'central_unit_type_filter'];

  initialize() {
    document.querySelector('#search').focus();
  }

  // todo register after reflex to move target element
  finalizeReflex(element, reflex, noop, reflexId) {

  }

  result(event) {
    let requestId = event.detail[0].body.querySelector('#request_id');
    if (requestId != undefined && this.requestId == requestId.value) {
      let actionSource = event.detail[0].body.querySelector('#action_source').value;
      var list = document.querySelector('.body #list');
      if (actionSource == 'search' || actionSource == '' || actionSource == undefined) {
        var centralUnitsList = event.detail[0].body.querySelector('body #list');
        list.parentElement.replaceChild(centralUnitsList, list);
      } else if (actionSource == 'pagination-link') {
        var centralUnits = event.detail[0].body.querySelectorAll('div.central-unit');
        centralUnits.forEach(function (centralUnit) {
          list.appendChild(centralUnit);
        });
      }
      this.replaceElementWithNewBySingleQuery('div#pagination', event)
      this.replaceElementWithNewBySingleQuery('#outset', event)
      this.replaceElementWithNewBySingleQuery('#step', event)
      this.spinnerOff();
    }
  }

  loadMore(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.spinnerOn();
    this.setRequestId();
    this.setActionSource(event.srcElement.id);
    Rails.fire(this.formTarget, 'submit');
  }
}
