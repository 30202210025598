import ApplicationController from './application_controller';

export default class extends ApplicationController {

  static targets = ['refreshSecond', 'refreshMessage'];

  initialize() {
    let delayStep = 1000;
    if (this.hasRefreshSecondTarget) {
      let waitToRefresh = parseInt(this.refreshSecondTarget.dataset.refreshSecond) * 1000;
      let element = this.refreshSecondTarget;
      let message = this.refreshSecondTarget.dataset.refreshMessage;
      let messageStop = this.refreshSecondTarget.dataset.refreshMessageStop;
      setTimeout(function () {
        if (element.dataset.canRefreshPage == 'true') {
          console.log("reloading page");
          location.reload(true);
        } else {
          console.log("stop reloading page");
        }
      }, waitToRefresh);

      var checkRefreshInterval = setInterval(function () {
        waitToRefresh -= delayStep;
        if (element.dataset.canRefreshPage == 'true') {
          element.textContent = message.replace('XXX', waitToRefresh / 1000);
        } else if (element.dataset.showStopToUser == 'true' ) {
          element.textContent = messageStop;
          clearInterval(checkRefreshInterval);
        } else {
          clearInterval(checkRefreshInterval);
          element.classList.add('hidden');
        }
      }, delayStep);
    }
  }

  delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }
}
