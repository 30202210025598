import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["recipient_type", "installation_id"];

  connect() {
    super.connect();
    const recipientType = this.recipient_typeTarget.value;
    const installationId = this.installation_idTarget.value;
    this.stimulate(
      "EmailDispatch#load_initial_state",
      this.recipient_typeTarget,
      recipientType,
      installationId
    );
  }

  emailTypeChanged(event) {
    const legend = document.getElementById("legend");
    const value = event.target.selectedOptions[0].dataset.legend;
    legend.innerHTML = value;
  }
}
