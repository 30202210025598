import ApplicationController from './application_controller';

export default class extends ApplicationController {

    static targets = ['dialog', 'content', 'header', 'trigger', 'confirmData'];

    open(event) {
        event.preventDefault();
        this.dialogTarget.showModal();
    }

    close(event) {
        event.preventDefault();
        this.dialogTarget.close();
    }

    submit(event) {
        event.preventDefault();
        this.spinnerOn(this.contentTarget.querySelector('#spinner'));
        const onSubmit = this.dialogTarget.dataset.onSubmit;

        this.stimulate(onSubmit, this.confirmDataTarget).then(() => {
            this.spinnerOff(this.contentTarget.querySelector('#spinner'));
            this.close(event);
            const onSuccessEvent = this.dialogTarget.dataset.onSuccessEvent;

            if (onSuccessEvent) {
                document.dispatchEvent(new CustomEvent(onSuccessEvent, { detail: { data: this.confirmDataTarget } }));
            }
        })
    }
}