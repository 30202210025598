import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ["product", "quantity"];

  addSale(event) {
    event.preventDefault();

    this.stimulate(
      'ProposalAddSaleReflex#add_sale',
      event.target,
      this.element.dataset.proposalId,
      this.productTarget.value,
      this.quantityTarget.value,
    )
    .then(payload => {
      const success_event = new CustomEvent('save-indicator:success', { detail: payload });
      window.dispatchEvent(success_event);
    })
    .catch(payload => {
      this.productTarget.classList.add('reflex-error');
      this.quantityTarget.classList.add('reflex-error');
      event.target.classList.add('reflex-error');
      const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
      window.dispatchEvent(failed_event);
    });
  }

}
