import ApplicationController from './application_controller';

export default class extends ApplicationController {

  static targets = ['input', 'street', 'city', 'zip', 'country', 'lat', 'long'];

  initialize() {
    var autocomplete = new google.maps.places.Autocomplete(this.inputTarget, { types: ['geocode'] });
    autocomplete.setFields(['address_component', 'geometry']);
    autocomplete.addListener('place_changed', () => {
      var place = autocomplete.getPlace();
      var address = this.extract(place);
      this.streetTarget.value = [address.street, address.street_number].join(' ');
      this.cityTarget.value = address.city;
      this.zipTarget.value = address.zip;
      this.countryTarget.value = address.country;
      this.latTarget.value = place.geometry.location.lat();
      this.longTarget.value = place.geometry.location.lng();
    });
    google.maps.event.addDomListener(this.inputTarget, 'keydown', (event) => {
      if (event.keyCode === 13) { event.preventDefault(); }
    });
  }

  extract(place) {
    return {
      street: (
        place.address_components.find(a => { return a.types.includes('route') }) || {}
      ).long_name || '',
      street_number: (
        place.address_components.find(a => { return a.types.includes('street_number') }) || {}
      ).long_name || '',
      city: (
        place.address_components.find(a => { return a.types.includes('locality') }) ||
        place.address_components.find(a => { return a.types.includes('sublocality') }) ||
        place.address_components.find(a => { return a.types.includes('administrative_area_level_2') }) ||
        {}
      ).long_name || '',
      zip: (
        place.address_components.find(a => { return a.types.includes('postal_code') }) || {}
      ).long_name || '',
      country: (
        place.address_components.find(a => { return a.types.includes('country') }) || {}
      ).short_name || '',
    }
  }

}
