import ApplicationController from './application_controller'
import Rails from "@rails/ujs";

export default class extends ApplicationController {
    static targets = ['pagination', 'searchFilterForm', 'page', 'tableBody', 'actionSource', 'spinner', 'pagination']

    initialize() {
        super.initialize();
        this.debounceTimer = null
        this.currentSearchInput = null
    }

    search(event) {
        this.currentSearchInput = event.target.name
        event.preventDefault()
        this.setActionSource(event.target.id)
        this.pageTarget.value = event.target.dataset.page || 1
        Rails.fire(this.searchFilterFormTarget, 'submit')
    }

    inputSearch(event) {
        event.preventDefault()
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer)
        }

        this.debounceTimer = setTimeout(() => {
            this.spinnerOn(this.spinnerTarget)
            this.search(event)
        }, 350)
    }

    searchResult(event) {
        event.preventDefault()
        this.spinnerOff(this.spinnerTarget)
        this.replaceElementWithNewBySingleQuery('.table-component-table', event)
        if(this.hasPaginationTarget) {
            this.replaceElementWithNewBySingleQuery('.pagination', event)
        }
        this.pushQueryParamsToUrl(this.searchFilterFormTarget)
        const inputElement = document.querySelector(`input[name="${this.currentSearchInput}"]`);
        if (this.actionSourceTarget.value === 'pagination') {
            this.scrollToStart('.table-component', 40)
        } else {
            inputElement.focus()
            inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length)
        }
    }
}