import ModalController from './modal_controller';

export default class extends ModalController {
  static targets = ['roleType'];

  show(event) {
    super.show(event);

    this.stimulate(
      (event.target.dataset.showReflexClass || 'PersonReflex') + '#show_person_modal',
      event.currentTarget,
    );
  }

  changeRole(event) {
    if (this.hasRoleTypeTarget) {
      this.roleTypeTarget
    }
    let setting = JSON.parse(event.currentTarget.dataset.setting)

    if (setting[event.currentTarget.value] != null) {
      let form = event.srcElement.closest('form');
      setting  = setting[event.currentTarget.value][0];
      Object.keys(setting).forEach(key => {
        if (key != 'id') {
          let input = form.querySelector("#installations_person_" + key);
          if (input != null) { // revisit for all type of inputs
            input.checked = setting[key];
          } 
        }        
      });

    } else { 
      console.log("Missing setting for role " + event.currentTarget.value); 
    }
  }

  submitPerson(event) {
    event.preventDefault();

    // Prevent double click.
    var target = event.currentTarget;
    var originalLabel = target.innerHTML;
    if (target.disabled === true) { return }
    target.disabled = true;
    target.innerHTML = event.currentTarget.dataset.stimulusDisableWith;

    this.stimulate(
      (event.target.dataset.submitReflexClass || 'PersonReflex') + '#' + (event.target.dataset.submitReflexAction || 'submit_person_modal'), event.target)
      .then(payload => {
        this.closeModal();
        const success_event = new CustomEvent('save-indicator:success', { detail: payload });
        window.dispatchEvent(success_event);
        if (payload.event.detail.html) {
          let parser = new DOMParser();
          let element = parser.parseFromString(payload.event.detail.html, 'text/html');
          let redirectLink = element.querySelector('a#redirect_to');
          if (redirectLink != undefined) {
            window.location.replace(redirectLink.getAttribute('href'))
          }
        }
      })
      .catch(payload => {
        const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
        let title = payload.element.closest('.modal-dialog').querySelector('.header h4');
        title.innerHTML = payload.event.detail.stimulusReflex.serverMessage.body;
        title.classList.toggle('error')
        window.dispatchEvent(failed_event);
      })
      .finally(() => {
        target.disabled = false;
        target.innerHTML = originalLabel;
      });
  }
}
