import ToggleElementController from "./toggle_element_controller";

export default class extends ToggleElementController {
  static targets = ["form", "monitorable_type"];

  initialize() {
    document.addEventListener("click", (event) => {
      if (event.target.matches(".dispatch-tooltip")) {
        console.log("dispatch-tooltip", event.target.dataset);
      }
    });
  }

  creataTableContainer(id, parentId) {
    const element = document.createElement("div");
    element.id = id;
    const parent = document.getElementById(parentId);
    parent.appendChild(element);
  }

  toggleOne(event) {
    super.toggleOne(event);
    const tablePage = document.getElementById("table-page-1");
    if (tablePage) return;
    this.loadReports(event);
  }
  loadReports(event) {
    const element = this.createSpinnerElement();
    this.spinnerOn(element);
    this.creataTableContainer("table-page-1", "tables-container");
    this.stimulate(
      "Reports#installation_reports_next_page",
      event.currentTarget
    );
  }

  loadNextReportsPage(event) {
    const element = this.createSpinnerElement();
    this.spinnerOn(element);

    const page = parseInt(event.currentTarget.dataset.page, 10);
    const tablePage = document.getElementById("table-page-" + page.toString());

    const tablePages = document.querySelectorAll("[id^='table-page-']");
    tablePages.forEach((tablePage) => {
      tablePage.classList.add("d-none");
    });
    if (tablePage) {
      tablePage.classList.remove("d-none");
      this.spinnerOff(element);
      return;
    }

    this.creataTableContainer(
      "table-page-" + page.toString(),
      "tables-container"
    );

    this.stimulate(
      "Reports#installation_reports_next_page",
      event.currentTarget
    );
  }

  afterReflex(element, reflex) {
    this.spinnerOff(element);
  }
}
