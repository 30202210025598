import ApplicationController from "./application_controller";
import flatpickr from "flatpickr";

export default class extends ApplicationController {
  static targets = ["dueDate", "percentAmount", "invoiceType"];
  flatpickrIninialized = false;

  initialize() {
    console.log("invoice controller initialized");
    flatpickr(this.dueDateTarget, {
      dateFormat: "Y-m-d",
      altInput: true,
      altFormat: "d.m.Y",
      locale: this.getLocale(),
    });
  }

  handleInvoiceTypeChange(event) {
    if (event.target.value === "advance_invoice") {
      this.percentAmountTarget.classList.remove("hidden");
    } else {
      this.percentAmountTarget.classList.add("hidden");
    }
  }
}
