import ApplicationController from './application_controller';
import flatpickr from 'flatpickr';

export default class extends ApplicationController {

  static targets = ['input', 'hidden', 'results', 'autocomplete.hidden', 'expectedInstallationAt', 'acquired']

  connect() {
    document.addEventListener("autocomplete.change", this.changeAcqueredPerson.bind(this));
    super.connect();
  }

  initialize() {
    if (this.hasExpectedInstallationAtTarget) {
      this.flatpickrToAllDeliveryForms();
    }
  }

  assingFlatpickr(input, method) {
    var controller = this;
    flatpickr(input, {
      locale: this.getLocale(),
      dateFormat: 'd.m.Y',
      onChange: function (selectedDates, dateStr, instance) {
        controller[method](selectedDates, dateStr, instance);
      }
    });
  }

  flatpickrToAllDeliveryForms() {
    Array.from(document.querySelectorAll('input[id^="installation_expected_installation_at"]')).forEach(input => this.assingFlatpickr(input, 'setExpectedShipmentAt'));
  }

  setExpectedShipmentAt(_selectedDates, dateStr, flatpickr_instance) {
    event.preventDefault();
  }

  changeAcqueredPerson(event) {
    if (event.srcElement.id == "acquired_by"){
      event.preventDefault();
      event.stopImmediatePropagation();

      this.stimulate('InstallationReflex#change_acquired_by', event.target, event.target.querySelector('#acquired_by_id').value).catch(payload => {
        const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
        window.dispatchEvent(failed_event);
      });
    } else if (event.srcElement.id == "business_owner") {
      event.preventDefault();
      event.stopImmediatePropagation();

      this.stimulate('InstallationReflex#change_business_owner', event.target, event.target.querySelector('#sales_person_id').value).catch(payload => {
        const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
        window.dispatchEvent(failed_event);
      });
    }
  }

  submit(event) {
    if (!event.srcElement.closest('form').classList.contains('installation-form')) {
      return
    }
    this.stimulate('InstallationReflex#submit', event.target)
      .then(payload => {
        if (payload.event.detail.stimulusReflex.serverMessage != undefined && payload.event.detail.stimulusReflex.serverMessage.subject == "halted") {
          const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
          window.dispatchEvent(failed_event);
        } else {
          const success_event = new CustomEvent('save-indicator:success', { detail: payload });
          window.dispatchEvent(success_event);
        }
      })
      .catch(payload => {
        const { data, element, event } = payload;
        const { attrs, reflexId } = data;
        const error = event.detail.stimulusReflex.serverMessage.body;
        // console.log(payload);
        // console.log(data);
        // console.log(element);
        // console.log(event);
        // console.log(attrs);
        // console.log(reflexId);
        console.log(error);
        const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
        window.dispatchEvent(failed_event);
      });
  }

  submitSalesType(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.stimulate("InstallationReflex#submit_sales_type", event.target)
      .then((payload) => {
        const success_event = new CustomEvent("save-indicator:success", {
          detail: payload,
        });
        window.dispatchEvent(success_event);
      })
      .catch((payload) => {
        const failed_event = new CustomEvent("save-indicator:failed", {
          detail: payload,
        });
        window.dispatchEvent(failed_event);
      });
  }

  destroyCompany(event) {
    event.preventDefault();

    if (confirm(event.currentTarget.dataset.stimulusConfirm)) {
      this.stimulate('CompanyReflex#destroy_company', event.target, event.currentTarget.dataset.id)
        .then(payload => {
          const success_event = new CustomEvent('save-indicator:success', { detail: payload });
          window.dispatchEvent(success_event);
        })
        .catch(payload => {
          const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
          window.dispatchEvent(failed_event);
        });
    }
  }

  destroyPerson(event) {
    event.preventDefault();

    if (confirm(event.currentTarget.dataset.stimulusConfirm)) {
      this.stimulate('PersonReflex#destroy_person', event.target, event.currentTarget.dataset.id)
        .then(payload => {
          const success_event = new CustomEvent('save-indicator:success', { detail: payload });
          window.dispatchEvent(success_event);
        })
        .catch(payload => {
          const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
          window.dispatchEvent(failed_event);
        });
    }
  }

  destroyAddress(event) {
    event.preventDefault();

    if (confirm(event.currentTarget.dataset.stimulusConfirm)) {
      this.stimulate('AddressReflex#destroy_address', event.target, event.currentTarget.dataset.id)
        .then(payload => {
          const success_event = new CustomEvent('save-indicator:success', { detail: payload });
          window.dispatchEvent(success_event);
        })
        .catch(payload => {
          const failed_event = new CustomEvent('save-indicator:failed', { detail: payload });
          window.dispatchEvent(failed_event);
        });
    }
  }
}
