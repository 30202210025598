import ToggleElementController from "./toggle_element_controller";

export default class extends ToggleElementController {
    static targets = ["form", "monitorable_type"];

    initialize() {
        document.addEventListener("click", (event) => {
            if (event.target.matches(".dispatch-tooltip")) {
                console.log("dispatch-tooltip", event.target.dataset);
            }
        });
    }

    createTableContainer(id, parentId) {
        const element = document.createElement("div");
        element.id = id;
        const parent = document.getElementById(parentId);
        parent.appendChild(element);
    }

    toggleOne(event) {
        super.toggleOne(event);
        const tablePage = document.getElementById("guarantees-table-page-1");
        if (tablePage) return;
        this.loadGuarantees(event);
    }
    loadGuarantees(event) {
        const element = this.createSpinnerElement();
        this.spinnerOn(element);
        this.createTableContainer("guarantees-table-page-1", "guarantees-tables-container");
        this.stimulate(
            "Guarantees#installation_guarantees_next_page",
            event.currentTarget
        );
    }

    loadNextGuaranteesPage(event) {
        const element = this.createSpinnerElement();
        this.spinnerOn(element);

        const page = parseInt(event.currentTarget.dataset.page, 10);
        const tablePage = document.getElementById("guarantees-table-page-" + page.toString());

        const tablePages = document.querySelectorAll("[id^='guarantees-table-page-']");
        tablePages.forEach((tablePage) => {
            tablePage.classList.add("d-none");
        });
        if (tablePage) {
            tablePage.classList.remove("d-none");
            this.spinnerOff(element);
            return;
        }

        this.createTableContainer(
            "guarantees-table-page-" + page.toString(),
            "guarantees-tables-container"
        );

        this.stimulate(
            "Guarantees#installation_guarantees_next_page",
            event.currentTarget
        );
    }

    afterReflex(element, reflex) {
        this.spinnerOff(element);
    }
}
