import ApplicationController from './application_controller';
import Rails from "@rails/ujs"

export default class extends ApplicationController {

    static targets = ['asB2C', 'asB2B', 'asB2CBtn', 'asB2BBtn', 'customerType', 'wFp', 'woFp', 'wFpBtn', 'woFpBtn', 'withOrWithoutFloorplan']

    as_b2c() {
        this.setRequestId();
        document.querySelector('#customer_type').value = 'b2c';
        Rails.fire(this.customerTypeTarget, 'submit');
    }

    as_b2b() {
        this.setRequestId();
        document.querySelector('#customer_type').value = 'b2b';
        Rails.fire(this.customerTypeTarget, 'submit');
    }

    resultCustomerType(event){
        let requestId = event.detail[0].body.querySelector('#request_id');
        if (requestId != undefined && this.requestId == requestId.value) {
            let newDeliveryForm = event.detail[0].body.querySelector('.demand');
            if (newDeliveryForm != undefined ) {
              let oldDeliveryForm = document.querySelector('.demand');
              oldDeliveryForm.parentElement.replaceChild(newDeliveryForm, oldDeliveryForm);
              return
            }
          }
    }

    w_fp() {
        this.wFpTarget.style.display = 'block'
        this.woFpTarget.style.display = 'none'
        this.wFpBtnTarget.classList.add('selected')
        this.woFpBtnTarget.classList.remove('selected')
        this.withOrWithoutFloorplanTarget.value = 'with'
    }

    wo_fp() {
        this.wFpTarget.style.display = 'none'
        this.woFpTarget.style.display = 'block'
        this.wFpBtnTarget.classList.remove('selected')
        this.woFpBtnTarget.classList.add('selected');
        this.withOrWithoutFloorplanTarget.value = 'without'
    }

}
