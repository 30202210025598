import consumer from '../channels/consumer';
import ModalController from './modal_controller';
import Rails from "@rails/ujs"

export default class extends ModalController {

  static targets = ['runTest', 'findSensorsForm'];

  applyEvent(event) {
    let form = event.srcElement.closest('form');
    let spinnerDiv = form.querySelector('#spinner');
    this.spinnerOn(spinnerDiv);
    let controller = this;

    this.stimulate(
      'CentralUnitReflex#apply_event',
      event.target,
      document.querySelector('#state').value
    ).then(_payloed => { location.reload(); }).finally(() => { controller.spinnerOff(spinnerDiv); });
  }

  sendFindSensors(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    let element = event.srcElement;
    let progressBar = document.querySelector('#progress-bar #gauge');
    let lastProgress = progressBar.dataset.progress;
    let sameFor = 0;
    Rails.fire(this.findSensorsFormTarget, 'submit');
    var checkInterval = setInterval(function () {
      let progressBar = document.querySelector('#progress-bar #gauge');
      if (lastProgress == progressBar.dataset.progress) {
        sameFor += 5000;
      } else {
        sameFor = 0;
      }

      if (sameFor > 30000) { // usually the connection is established under 10s
        console.log("Too long time without change. Seems that CU does not respond")
        let centralUnitActivationChannel = consumer.subscriptions.create({
          channel: 'CentralUnitActivationChannel', central_unit_id: element.dataset.centralUnitId,
          locale: document.querySelector('body').dataset.locale
        }, { received(_data) { } });
        centralUnitActivationChannel.send({ central_unit_id: element.dataset.centralUnitId, connection: 'lost' });
        clearInterval(checkInterval);
      } else if (document.querySelector('.buttons-to-activate-central-unit-component')) {
        document.querySelectorAll("[data-can-refresh-page]").forEach(function (element) {
          element.dataset.canRefreshPage = false;
        });
        console.log("Stop detecting failed connection. It is possible to create activation.")
        clearInterval(checkInterval);
      }
      lastProgress = progressBar.dataset.progress;
    }, 5000);
  }

  showChangeStateModal(event) {
    super.show(event);

    this.stimulate(
      'CentralUnitReflex#show_change_state_modal',
      event.target
    );
  }

  changeSelectedState(event) {
    let form = event.srcElement.closest('form');
    let spinnerDiv = form.querySelector('#spinner');
    this.spinnerOn(spinnerDiv);
    let controller = this;

    this.stimulate(
      'CentralUnitReflex#show_change_state_modal',
      event.target,
      document.querySelector('#state').value
    ).finally(() => { controller.spinnerOff(spinnerDiv); });
  }
}
